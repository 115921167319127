import { Box, Typography } from "@mui/material";
import React from "react";
import "./index.css";
// import redball from "../../images/Red-ball.png";

const index = ({ Images, className, text, popupScreen, disable, style }) => {
  return (
    <Box
      lg={1}
      className={className}
      disable={disable}
      style={style}
      onClick={() => {
        if (!disable) popupScreen();
      }}
    >
      <img src={Images} alt="Images" className="ActionButton" />
      <Typography
        variant="body2"
        fontFamily={"Bebas"}
        fontSize={22}
        letterSpacing="1px"
        className="Name"
        // style={{pointerEvents:"cursor"}}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default index;

import { warningMsg } from '../../services/helpers';

export default function ValidateGame(straightBetData, straightBetCount, rambolito3BetData, rambolito3Count, rambolito6BetData, rambolito6Count) {
    let str_length = straightBetData.filter((e) => e.length == 3)?.length
    let rum_length = rambolito3BetData.filter((e) => e.length == 3)?.length
    let rum6_length = rambolito6BetData.filter((e) => e.length == 3)?.length

    const straightCondition = straightBetCount > 0 && str_length > 0;
    const rumbleCondition = rambolito3Count > 0 && rum_length > 0;
    const rumble6Condition = rambolito6Count > 0 && rum6_length > 0;

    let validatePlaceBet=rum_length == 0 && straightBetCount < 1 && rambolito3Count < 1 && str_length == 0 && rambolito6Count < 1 && rum6_length == 0;
    console.log({validatePlaceBet,str_length,rum_length,rum6_length,straightCondition,rumbleCondition,rumble6Condition})
    if (validatePlaceBet) {
        warningMsg("Please Select Data To Place Bet");
        console.log("HIIII")
        return 1;
    } else if (straightCondition || rumbleCondition || rumble6Condition) {
        return 0;
    }else if(straightBetCount > 0 || str_length > 0){
        if (str_length == 0) {
          warningMsg("Please select a pair of numbers from STRAIGHT BET");
          return 1;
        }else {
          warningMsg("Please select STRAIGHT BET amount");
          return 1;
        }
      }else if(rambolito3Count > 0 || rum_length > 0){
        if (rum_length == 0 ) {
          console.log("rum_length", rum_length)
          warningMsg("Please select a pair of numbers from Rambilto3Bet");
          return 1;
        }else {
          warningMsg("Please select Rambilto3Bet amount ");
          return 1;
        }
      }else if(rambolito6Count > 0 || rum6_length > 0){
        if (rum6_length == 0 ) {
          console.log("rum6_length", rum6_length)
          warningMsg("Please select a pair of numbers from Rambilto6Bet");
          return 1;
        }else {
          warningMsg("Please select Rambilto6Bet amount ");
          return 1;
        }
      }


    // if (straightBetData[straightBetData?.length - 1]?.length < 3) {
    //     warningMsg("Please Fill Numbers In Active Straight Bet");
    //     return 1;
    // } else if (rambolito3BetData[rambolito3BetData?.length - 1]?.length < 3) {
    //     warningMsg("Please Fill Numbers In Active Ramblito3 Bet");
    //     return 1;
    // } else if (rambolito6BetData[rambolito6BetData?.length - 1]?.length < 3) {
    //     warningMsg("Please Fill Numbers In Active Ramblito3 Bet");
    //     return 1;
    // } else if ((straightBetData?.length === 0 && straightBetCount < 1) && (rambolito3BetData?.length === 0 && rambolito3Count < 1) && (rambolito6BetData?.length === 0 && rambolito6Count < 1)) {
    //     warningMsg("Please Select Data To Place a Bet");
    //     return 1;
    //     // } else if ((straightBetData?.length < 1 && straightBetCount > 0) && (rambolito3BetData?.length < 1 && rambolito3Count < 1) && (rambolito6BetData?.length < 1 && rambolito6Count < 1)) {
    // } else if ((straightBetData?.length < 1 && straightBetCount > 0)) {
    //     warningMsg("Please Select Numbers From Straight Bet");
    //     return 1;
    //     // } else if ((straightBetData?.length >= 1 && straightBetCount === 0) && (rambolito3BetData?.length < 1 && rambolito3Count < 1) && (rambolito6BetData?.length < 1 && rambolito6Count < 1)) {
    // } else if ((straightBetData?.length >= 1 && straightBetCount === 0)) {
    //     warningMsg("Please Select Bet Amount For Straight Bet");
    //     return 1;
    //     // } else if ((straightBetData?.length <= 1 && straightBetCount < 1) && (rambolito3BetData?.length < 1 && rambolito3Count > 0) && (rambolito6BetData?.length < 1 && rambolito6Count < 1)) {
    // } else if ((rambolito3BetData?.length < 1 && rambolito3Count > 0)) {
    //     warningMsg("Please Select Numbers From Rambilto3Bet");
    //     return 1;
    //     // } else if ((straightBetData?.length < 1 && straightBetCount > 0) || (rambolito3BetData?.length >= 1 && rambolito3Count === 0) || (rambolito6BetData?.length < 1 && rambolito6Count < 1)) {
    // } else if ((rambolito3BetData?.length >= 1 && rambolito3Count === 0)) {
    //     warningMsg("Please Select Bet Amount For Rambilto3Bet");
    //     return 1;
    // } else if ((rambolito6BetData?.length <= 1 && rambolito6Count > 0)) {
    //     warningMsg("Please Select Numbers From Rambilto6Bet");
    //     return 1;
    // } else if ((rambolito6BetData?.length >= 1 && rambolito6Count < 1)) {
    //     warningMsg("Please Select Bet Amount For Rambilto6Bet");
    //     return 1;
    // }
    // else { return 0 }
}
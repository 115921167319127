import { Box } from '@mui/material';
import React from 'react';
import ReactModal from 'react-modal';
import betterLuckImg from '../../../images/resultPopup/Better-luck-new.png';
import betterLuckBg from '../../../images/resultPopup/Shadow-better-luck-next-tym.png';
import "../../../player/Mega2ball/index.css";

export const LoserModal = ({resultPopup}) => {
  return (
    <ReactModal isOpen={resultPopup} className={"result-lose-popup"}
        onAfterOpen={() => document.body.style.overflow = 'hidden'}
        onAfterClose={() => document.body.style.overflow = 'unset'}>
        <img src={betterLuckBg} alt="" className="betterLuck-bg" />

        <Box className="result-lose-popup-bg">
          <img src={betterLuckImg} alt="" className="betterLuck-Image" />
        </Box>
        <Box className="betterLuck-text-box">
          <p className="betterLuck-text">
            {" "}
            <span className="span1">Better luck</span>
            <br /> <span className="span2">next time</span>
          </p>
        </Box>
      </ReactModal>
  )
}

import { Box, Grid, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import BetHandle2 from "../../player/Mega2ball/BetHandle2";
import TotalAmount from "../TotalAmount";
import AddIcon from "@mui/icons-material/Add";

export const ShowBoxes = ({
  index,
  curentInput,
  BetDisable,
  rumbleData,
  roundRedBallData,
  roundBlueBallData,
  setCurentInput,
  data,
  setDisableRedBox,
  setDisableBlueBox,
  straightBetClickStatus,
  type,
}) => {
  return (
    <Box
      key={index}
      className={`boxGroup ${curentInput === index ? "active" : ""}`}
      disabled={!BetDisable}
      onClick={() => {
        if (straightBetClickStatus) {
          return 1;
        }
        if (data && data[index]?.length === 1) {
          setDisableBlueBox(false);
          setDisableRedBox(true);
        } else {
          setDisableBlueBox(true);
          setDisableRedBox(false);
        }
        setCurentInput(index);
      }}
    >
      {/* {console.log(roundRedBallData,roundBlueBallData, rumbleData?.[index]?.[0],"@27")} */}
      {type == "STRAIGHT" ? (
        <>
          <Box
            className={
              roundRedBallData > -1 &&
              roundRedBallData == rumbleData?.[index]?.[0]
                ? "box redNumberResult"
                : "box"
            }
          >
            {" "}
            {rumbleData?.[index]?.[0]}{" "}
          </Box>
          <Box
            className={
              roundBlueBallData > -1 &&
              roundBlueBallData == rumbleData?.[index]?.[1]
                ? "box1 redNumberResult"
                : "box1"
            }
          >
            {" "}
            {rumbleData?.[index]?.[1]}
          </Box>
        </>
      ) : (
        <>
          <Box
            className={
              roundRedBallData > -1 &&
              (roundRedBallData == rumbleData?.[index]?.[0] ||
                roundBlueBallData == rumbleData?.[index]?.[0])
                ? "box redNumberResult"
                : "box"
            }
          >
            {" "}
            {rumbleData?.[index]?.[0]}{" "}
          </Box>
          <Box
            className={
              roundBlueBallData > -1 &&
              (roundRedBallData == rumbleData?.[index]?.[1] ||
                roundBlueBallData == rumbleData?.[index]?.[1])
                ? "box1 redNumberResult"
                : "box1"
            }
          >
            {" "}
            {rumbleData?.[index]?.[1]}
          </Box>
        </>
      )}
    </Box>
  );
};

export const BetHeader = ({
  BetDisable,
  sx,
  className,
  subClass,
  rumblePayoutClass,
  PayoutCount,
  PayoutKey,
  rumbleImage,
  handleRumbleBet,
  allButtonDisable,
  title,
  playSoftButtonClickAudio,
  showMute,
  setCountRumbleBet,
  countRumbleBet,
  rumblePayout,
  amount,
  balance,
  tempCountStraightBet,
  disableCount,
}) => {
  return (
    <Grid item className={className} xs={6} lg={3} sx={sx} container>
      <BetHandle2
        disabled={BetDisable}
        Images={rumbleImage}
        className={"actionButton"}
        text={title}
        handleBet={handleRumbleBet}
        style={{
          opacity: allButtonDisable ? "0.5" : "",
          pointerEvents: allButtonDisable ? "none" : "",
        }}
      />
      <Grid
      item
        xs={12}
        sx={{ order: { xs: "3", lg: "2" }, position:{xs: 'relative'} }}
        className={subClass}
        style={{
          opacity: allButtonDisable ? "0.5" : "",
          pointerEvents: allButtonDisable ? "none" : "",
        }}
      >
        <Typography
          margin={0}
          sx={{ display: { xs: "none", lg: "block" } }}
          className="textRumbleBet"
        >
          {title}
        </Typography>
        <Box className="pluseMinusRumble">
          <button
            className="minus"
            disabled={BetDisable}
            onClick={() => {
              if (showMute === false) {
                playSoftButtonClickAudio();
              }
              if (!disableCount) {
                countRumbleBet >= 1
                  ? setCountRumbleBet(Number(parseInt(countRumbleBet) - 1))
                  : setCountRumbleBet(Number(parseInt(countRumbleBet)));
              }
            }}
          >
            <RemoveIcon style={{ fontSize: "20px" }} />{" "}
          </button>
          <input
            type="number"
            className="zero"
            value={countRumbleBet}
            style={{ minWidth: "12%", width: "auto", textAlign: "center" }}
            onChange={(event) => setCountRumbleBet(event.target.value)}
            disabled={disableCount}
          />
          <button
            className="plus"
            disabled={BetDisable}
            onClick={() => {
              if (showMute === false) {
                playSoftButtonClickAudio();
              }
              if (!disableCount) setCountRumbleBet(Number(countRumbleBet) + 1);
            }}
          >
            <AddIcon />
          </button>
        </Box>
      </Grid>
      <Grid
      item
        className="rumblePayoutBox"
        xs={12}
        md={6}
        lg={12}
        sx={{
          order: { xs: "2", lg: "3" },
          maxWidth: { xs: "49%", sm: "50%", md: "100%" },
        }}
      >
        <TotalAmount
          Images={rumblePayout}
          text5={"payout"}
          payoutTotal={PayoutKey}
          text6={PayoutCount}
          className={rumblePayoutClass}
          ActionButtonMega2Ball="ActionButtonMega2Ball maxWid"
          style={{
            opacity: BetDisable ? "0.5" : "",
            position: "relative",
          }}
        />
      </Grid>
    </Grid>
  );
};

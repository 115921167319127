import React from 'react'
import ReactModal from 'react-modal'
import clickOkImages from "../../../images/SwitchGame/yes-button.png";
import PopupButtonsHandler from '../../../component/PopupButtonsHandler';
import { Box } from '@mui/material';
import "../../../player/Mega2ball/index.css";

const PausedModal = ({ timerStopPopup, msg, clickOkPaused }) => {
    return (
        <ReactModal isOpen={timerStopPopup} className="showStopTimerPopup"
            onAfterOpen={() => document.body.style.overflow = 'hidden'}
            onAfterClose={() => document.body.style.overflow = 'unset'}>
            <Box className="timerstoppopup">
                <Box className="stoppedTimer2">{msg}</Box>
                <Box className="buttonOkay">
                    {/* <PopupButtonsHandler
                        Images={clickOkImages}
                        text={"Okay"}
                        className="ClickOkayImage"
                        clickOkayPopup={clickOkPaused}
                    /> */}
                </Box>
            </Box>
        </ReactModal>
    )
}

export default PausedModal
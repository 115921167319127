import axios from "axios";
import { endsessionToastifyMsg, warningMsg } from "../services/helpers";

//Login Component for operator
export async function Login(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Account/operatorLogin`,
      value
    );
    return response.data;
  } catch (err) {
    warningMsg(err?.response?.message);
  }
}

//Logout component for operator
export async function Logout(value) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}Account/OperatorLogout?operatorId=${value}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tokenOperator")}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    warningMsg(err?.response?.message);
  }
}

//EndGameSession  For Mega 2 Ball
export async function EndGameSession(value, token) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}GameSession/EndGameSession`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    endsessionToastifyMsg("GameSession ended successfully");
    return response;
  } catch (err) {
    console.log("API error", err?.response?.status);
    if (err?.response?.status == 401) {
      let payload = {
        token: localStorage.getItem("tokenOperator"),
        refreshToken: localStorage.getItem("tokenOperatorRefresh"),
      };
      let genRefreshToken = await generateRefreshToken(payload);

      console.log("genRefreshToken",genRefreshToken)
      if (genRefreshToken) {
        localStorage.setItem("tokenOperator",genRefreshToken.token)
        localStorage.setItem("tokenOperatorRefresh",genRefreshToken.refreshToken)
        await EndGameSession(value, genRefreshToken?.token);
      } else {
        warningMsg("Session expired!");
      }
    } else {
      warningMsg(err?.response?.message);
    }
  }
}

// Submit results for operator in mega-2 Ball
export async function SubmitResultForMegaTwoBall(value, token) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}GameSession/SubmitGameResult-UpdateGameResult`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    if (err?.response?.status == 401) {
      warningMsg("Session expired!");
    } else {
      warningMsg(err?.response?.message);
    }
  }
}

//Chat List API for player page in mega-# ball
export async function ChatListMega(value) {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Chat/ListOfMessages`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return resp;
  } catch (errors) {
    warningMsg(errors?.response?.message);
  }
}
//Start Timer Api for mega-2 Ball in player page.
export async function StartTimer() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaTwo/StartTimer`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data;
  } catch (err) {
    warningMsg(err?.response?.message);
  }
}

//Pause Timer for Operator Page
export async function PauseTimer(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaTwo/PausePlayTimer`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data;
  } catch (err) {
    warningMsg(err?.response?.message);
  }
}

export async function PauseTimer3(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaThree/PausePlayTimer`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    warningMsg(error?.response?.message);
  }
}

export async function StartTimerMega3() {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_PREFIX}TimerMegaThree/StartTimer`,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return resp.data;
  } catch (errors) {
    warningMsg(errors?.response?.message);
  }
}
export async function SettleRoundMega3(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaThreeBall/SettleRound`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    warningMsg(error?.response?.message);
  }
}

export async function SettleRoundMega2(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}PlayerForMegaTwoBall/SettleRound`,
      value,
      {
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    warningMsg(error?.response?.message);
  }
}
//RefreshToken for operator
export async function generateRefreshToken(value) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PREFIX}Account/GenerateRefreshToken`,
      value
    );
    return response.data;
  } catch (err) {
    warningMsg(err?.response?.message);
  }
}

import { useEffect, useRef, useState } from "react";
import "./index.css";
import Modal from "react-modal";
import PointBox from "../../component/PointBox";
import CloseIcon from "@mui/icons-material/Close";
import ActionButton from "../../component/ActionButton";
import LineLeftRight from "../../component/LineLeftRight";
import RoundBall from "../../component/RoundBall";
import blueButton from "../../images/Blue_button1.png";
import redButton from "../../images/Red_button1.png";
import greenButton from "../../images/Green-button.png";
import redball from "../../images/red_ball1.png";
import greenBall from "../../images/green-ball.png";
import CancelPopup from "../../component/CancelPopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blueBall from "../../images/bule_ball1.png";
import imageLogo3 from "../../images/Mega-3-logo.png";
import bg from "../../images/bg-1.jpg";
import cancelButton from "../../images/cancel-button.png";
import pauseButton from "../../images/pause-button.png";
import endSession from "../../images/end-session.png";
import submit from "../../images/submit.png";
import lineLeft from "../../images/1st-line-left.png";
import lineRight from "../../images/1st-line-Right.png";
import { Box, Container, Grid, Stack } from "@mui/material";
import SubmitPOPup from "../../images/Submit-wo-shadow.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChatListMega,
  EndGameSession,
  Logout,
  PauseTimer3,
  StartTimer,
  StartTimerMega3,
  SubmitResultForMegaTwoBall,
} from "../../APIHandler/OperatorAPI";
import GameSwitchHandler from "../../component/GameSwitchHandler";
import noEndSessionButton from "../../images/SwitchGame/no-button.png";
import yesEndSessionButton from "../../images/SwitchGame/yes-button.png";
import underlineEndSession from "../../images/InfoScreen/underline.png";

import Connection from "../../services/SignalRConnectionHub";
import {
  submitResultForOperatorMega2Ball,
  warningMsg,
} from "../../services/helpers";
import moment from "moment";
import BeepAudio from "../../Asset/audio/beep.mp3";
import PopupAudio from "../../Asset/audio/Popup.mp3";
import SoftButtonClickAudio from "../../Asset/audio/SoftGameButtonClick.mp3";
import PlayfulMusicalGameEndingAudio from "../../Asset/audio/GameEnding/PlayfulMusicalGameEnding.mp3";
import MagicBonusReveal from "../../Asset/audio/magic_bonus_reveal.mp3";
import CombinationAudio from "../../Asset/audio/Pop02.mp3";
import TimerAudio from "../../Asset/audio/GameTimer_5_v1.wav";
import useSound from "use-sound";
import { cancelRoundMega3, TimerStateMega3 } from "../../APIHandler/API";
import TCPlayer from "tcplayer.js";
import { useScreenshot } from "use-react-screenshot";
import imageCompression from "browser-image-compression";

const OpareatorMega3Ball = () => {
  const [tokenAccess, setTokenAccess] = useState(
    localStorage.getItem("tokenOperator") || ""
  );

  const [redNumber, setRedNumber] = useState(-1);
  const [greenNumber, setGreenNumber] = useState(-1);
  const [blueNumber, setBlueNumber] = useState(-1);

  const [enterPopupRedBox, setEnterPopupRedBox] = useState(false);
  const [enterPopupGreenBox, setEnterPopupGreenBox] = useState(false);
  const [enterPopupBlueBox, setenterPopupBlueBox] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [actionBtnDisable, setActionBtnDisable] = useState(false);
  const [submitresults, setSubmitresults] = useState(false);
  const [sessionDisable, setSessionDisable] = useState(false);
  const [loading, setLoading] = useState(""); // State to manage loading

  const [openEndSessionPopup, setOpenEndSessionPopup] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [showMute, setShowMute] = useState(
    JSON.parse(localStorage.getItem("showMute"))
  );

  const [paused, setPaused] = useState(false);
  const [chats, setChats] = useState([]);

  const [minute, setMinute] = useState("");
  const [second, setSecond] = useState("");

  const [sessionIdd, setSessionIdd] = useState();

  const [playBeepAudio] = useSound(BeepAudio);
  const [playPopupAudio] = useSound(PopupAudio);
  const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
  const [playPlayfulMusicalGameEndingAudio] = useSound(
    PlayfulMusicalGameEndingAudio
  );
  const [playResultRevealAudio] = useSound(MagicBonusReveal);
  const [playCombinationAudio] = useSound(CombinationAudio);
  const [playTimerAudio] = useSound(TimerAudio);

  let x = JSON.parse(localStorage.getItem("showMute"));

  const resultRef = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  // const getImage = async () => {
  //   const capturedImage = await takeScreenshot(resultRef.current);
  //   actionSubmit(capturedImage); // Call actionSubmit with the captured image
  // };
  const compressImage = async (imageDataUrl) => {
    // Convert data URL to Blob
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();

    const options = {
      maxSizeMB: 0.2, // Maximum size in MB
      maxWidthOrHeight: 900, // Maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    return await imageCompression(blob, options);
  };
  const getImage = async (type) => {
    // Set loading to true when the button is clicked
    const capturedImage = await takeScreenshot(resultRef.current);

    const compressedImage = await compressImage(capturedImage);

    // Convert the compressed Blob to Base64
    const base64Image = await blobToBase64(compressedImage);

    if (type == "SUBMIT") actionSubmit(base64Image);
    else actionSubmit(base64Image); // Call actionSubmit with the captured image
  };
  // Helper function to convert Blob to Base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // This will be the Base64 string
      };
      reader.onerror = reject; // Handle errors
      reader.readAsDataURL(blob); // Convert Blob to Base64
    });
  };
  if (x !== true && x !== false) {
    localStorage.setItem("showMute", false);
    setShowMute(false);
  }
  const chatList = async () => {
    const response = await ChatListMega({ gameId: 2 });
    setChats(response?.data);
  };
  //StartTimer Api to start First Time WHen page is refresh
  useEffect(() => {
    const startTimerFunction = async () => {
      const response = await StartTimerMega3();
      console.log("startTimer 1");
      setMinute(response?.timer ? `${response?.timer}`?.split(":")[0] : "00");
      setSecond(response?.timer ? `${response?.timer}`?.split(":")[1] : "00");
      setPaused(response?.timerState === "Play" ? true : false);
      setSessionIdd(response?.gameSessionId);
      setRedNumber( response?.result?.results?.[0] == "-1" ? "" : response?.result?.results?.[0]);
      setGreenNumber( response?.result?.results?.[1] == "-1" ? "" : response?.result?.results?.[1]);
      setBlueNumber( response?.result?.results?.[2] == "-1" ? "" : response?.result?.results?.[2]);
      if (response?.result?.message === "Game Session Ended") {
        if (showMute === false) {
          playPlayfulMusicalGameEndingAudio();
        }
        setSessionDisable(true);
      }
    };
    startTimerFunction();
    chatList();
  }, []);

  // console.log("checking===", sessionDisable, actionBtnDisable, submitresults);
  useEffect(() => {
    const RestartTimer = async () => {
      if (minute === "00" && second === "00") {
        const resp = await StartTimerMega3();
        console.log("startTimer 2")
        // console.log("hii");
        setOpenCancelPopup(false);
        setOpenEndSessionPopup(false);
        if (resp) {
          setRedNumber(
            resp?.result?.results[0] > -1 ? resp?.result?.results[0] : -1
          );
          setGreenNumber(
            resp?.result?.results[1] > -1 ? resp?.result?.results[1] : -1
          );
          setBlueNumber(
            resp?.result?.results[2] > -1 ? resp?.result?.results[2] : -1
          );
          setSessionIdd(resp?.gameSessionId);
        }
      }
    };
    RestartTimer();
  }, [minute, second]);

  useEffect(() => {
    chatList();
  }, [sessionIdd]);

  //Connect to the signalR
  useEffect(() => {
    Connection.on("timerStopMegaThree", (message) => {});
    Connection.on("timerUpdateMegaThree", (message) => {
      setMinute(message ? `${message}`?.split(":")[0] : "00");
      setSecond(message ? `${message}`?.split(":")[1] : "00");
      if (message === "01:00") {
        warningMsg("Bet is closed");
      }
      if (message === "01:30") {
        warningMsg("Hurry up! Bet will close soon");
        if (showMute === false) {
          playTimerAudio();
        }
      }
    });

    Connection.off();
    Connection.on("SendMessageMegaThree", (chatMessage) => {
      setChats((prev) => [...prev, chatMessage]);
    });
  }, []);

  useEffect(() => {
    if (minute === "00" && second !== "00") {
      setActionBtnDisable(true);
    }
    if (minute === "00" && second === "00") {
      setActionBtnDisable(false);
      setSubmitresults(false);
      setSessionDisable(false);
    }
    if (minute !== "00") {
      // if (redNumber != -1 || blueNumber != -1 || greenNumber != -1) {
      //   setBlueNumber(-1);
      //   setGreenNumber(-1);
      //   setRedNumber(-1);
      // }
      setActionBtnDisable(false);
      setSubmitresults(false);
    }
  }, [minute, second]);

  useEffect(() => {
    if (!tokenAccess) navigate("/operatorLogin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAccess]);

  useEffect(() => {
    setEnterPopupRedBox(false);
    setEnterPopupGreenBox(false);
    setenterPopupBlueBox(false);
  }, [redNumber, greenNumber, blueNumber]);
  const actionCancel = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setOpenCancelPopup(true);
    setModalMessage("Please enter the correct result");
  };

  //Click END SESSION to end session && with fetch APi
  const clickPauseButton = async () => {
    setPaused(!paused);
    // let token=localStorage.getItem("tokenOperator")
    // console.log("timer state===",await TimerStateMega3(token))
    // const resp1 = await StartTimer();
    const value = {
      timerStoppedAt: `${minute}:${second}`,
      gameId: 2,
      gameSessionId: sessionIdd,
      timerState: !paused ? "Play" : "Pause",
      // resumedTime: resp1?.resumedTime,
    };
    if (!paused == true && sessionDisable == false) {
      setBlueNumber(-1);
      setGreenNumber(-1);
      setRedNumber(-1);
    }
    try {
      const responses = await PauseTimer3(value);
      const resp = await TimerStateMega3();
      // console.log("On un paused StartTimerMega3==",resp,"\nPauseTimer3==",responses, resp.gameSessionId , responses.gameSessionId,(resp.gameSessionId != responses.gameSessionId))
      console.log("first pause==", responses?.result != null);
      //=========
      console.log("TIMER STATE:", resp, "\n Pause:", responses,typeof resp?.data?.gameSessionId,typeof responses?.gameSessionId,resp?.data?.gameSessionId,responses?.gameSessionId,resp?.data?.gameSessionId != responses?.gameSessionId);
      if (responses?.result != null) {
        if (resp?.data?.gameSessionId != responses?.gameSessionId) {
          setRedNumber(-1);
          setBlueNumber(-1);
          setGreenNumber(-1);
          window.location.reload();
          const RestartTimer = async () => {
            setSessionIdd(resp?.data?.gameSessionId);
            // if (minute === "00" && second !== "00") {
            //   setActionBtnDisable(true);
            // }
            // if (minute === "00" && second === "00") {
            //   setActionBtnDisable(false);
            //   setSubmitresults(false);
            //   setSessionDisable(false);
            // }
            if (minute !== "00") {
              const resp = await StartTimerMega3();
              console.log("startTimer 3")
              // console.log("hii");
              setOpenCancelPopup(false);
              setOpenEndSessionPopup(false);

              setRedNumber(-1);
              setGreenNumber(-1);
              setBlueNumber(-1);
              setSessionIdd(resp?.gameSessionId);
              if (resp?.result?.message === "Game Session Ended") {
                setSessionDisable(true);
                if (showMute === false) {
                  playPlayfulMusicalGameEndingAudio();
                }
              }
            }          
             if (minute === "00" && second !== "00") {
                setSubmitresults(true);
             }
          };
          RestartTimer();
        }
      }

      // ====
      if (showMute === false) {
        playBeepAudio();
      }
    } catch (error) {
      warningMsg("Something went wrong");
    }
  };
  const { state } = useLocation();
  const handleEndSession = () => {
    if (showMute === false) {
      playPopupAudio();
    }
    setOpenEndSessionPopup(true);
  };
  const clickNoForEndSession = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setOpenEndSessionPopup(false);
  };

  const clickYesForEndSession = async () => {
    const endSessionFunc = {
      gameId: 2,
      timer: new Date(),
      gameSessionId: sessionIdd,
    };
    try {
      const ress = await EndGameSession(endSessionFunc, tokenAccess);
      if (showMute === false) {
        playPlayfulMusicalGameEndingAudio();
      }
    } catch (error) {
      if (showMute === false) {
        playSoftButtonClickAudio();
      }
    }
    setOpenEndSessionPopup(false);
    submitResultForOperatorMega2Ball("Session Ended Successfully");
    setSessionDisable(true);
  };

  //Click to submit result Button after select Number
  const actionSubmit = async (capturedImage) => {
    //take screenshot of results for disputes
    if (redNumber == -1 || greenNumber == -1 || blueNumber == -1) {
      warningMsg("Please select results");
    } else {
      const submitResultFunc = {
        gameSessionId: sessionIdd,
        results: [redNumber, greenNumber, blueNumber],
        gameId: 2,
        ImageFileBase64: capturedImage,
      };

      try {
        const res = await SubmitResultForMegaTwoBall(
          submitResultFunc,
          tokenAccess
        );
        if (res) {
          submitResultForOperatorMega2Ball("Result Submitted Successfully");
          setSubmitresults(true);
        }
        if (showMute === false) {
          playResultRevealAudio();
        }
      } catch (error) {
        warningMsg("Something went wrong");
      }
    }
    setLoading("");
  };
  const handleSubmitResult = async () => {
    setLoading("submit");
    //take screenshot of results for disputes
    await getImage("SUBMIT");
  };
  const handlepopupSubmit = async () => {
    setLoading("cancel");
    getImage("CANCEL_SUBMIT");
    setOpenCancelPopup(false);
  };
  const handlePopupRedButton = (value) => {
    if (showMute === false) {
      playCombinationAudio();
    }
    setEnterPopupRedBox(true);
  };
  const handlePopupGreenButton = (value) => {
    if (showMute === false) {
      playCombinationAudio();
    }
    setEnterPopupGreenBox(true);
  };
  const handlePopupBlurButton = (value) => {
    if (showMute === false) {
      playCombinationAudio();
    }
    setenterPopupBlueBox(true);
  };
  let navigate = useNavigate();
  const handleLogout = async () => {
    // Clear the token
    localStorage.removeItem("token");
    setTokenAccess("");
    let opt = localStorage.getItem("OptId");
    let res = await Logout(opt);
    localStorage.removeItem("token");
    localStorage.removeItem("OptId");
    navigate("/operatorLogin");
  };

  const selectRedNumber = (e) => {
    setRedNumber(e);
    if (showMute === false) {
      playCombinationAudio();
    }
  };
  const selectBlueNumber = (e) => {
    setBlueNumber(e);
    if (showMute === false) {
      playCombinationAudio();
    }
  };
  const selectGreenNumber = (e) => {
    setGreenNumber(e);
    if (showMute === false) {
      playCombinationAudio();
    }
  };

  const player = useRef();

  useEffect(() => {
    player.current = TCPlayer("liveVideoPlayer", {
      sources: [
        {
          src: process.env.REACT_APP_MEGA2_LIVE_URL,
        },
      ],
      licenseUrl: process.env.REACT_APP_TCPLAYER_LICENSE_KEY, // License URL, see the preparation section. You can obtain the licenseUrl after applying for a license in the RT-Cube console
      language: "en", // Setting language en | zh-CN
    });

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);
  return (
    <Box
      marginX={"85"}
      style={{
        backgroundImage: `url(${bg})`,
        minHeight: "100vh",
        marginTop: 0,
        backgroundSize: "cover",
      }}
      ref={resultRef}
    >
      <ToastContainer />
      {/* End session Popup open - When the operator click on END-SESSION buttons  */}
      <Modal isOpen={openEndSessionPopup} className="showStopTimerAlert">
        <Box className="endGame-session-Bg">
          <button
            onClick={() => {
              setOpenEndSessionPopup(false);

              if (showMute === false) {
                playSoftButtonClickAudio();
              }
            }}
            className="cancelPopup"
          >
            {" "}
            <CloseIcon fontSize="20px" />
          </button>
          <Box className="underlineSwitchGameBox">
            <p>End Session</p>
            <img
              src={underlineEndSession}
              alt="underlineSwitchGame"
              className="underline-endGameSession"
            />
          </Box>
          <Box className="buttonNoYes-endSession-Box">
            <p className="text-endGameBox">
              Do you want to <br></br> end the session?
            </p>
            <Box className="buttonNoYes-endSession">
              <GameSwitchHandler
                Images={noEndSessionButton}
                text={"No"}
                className="ClickOkayImage"
                handleGameSwitch={clickNoForEndSession}
              />
              <GameSwitchHandler
                Images={yesEndSessionButton}
                text={"Yes"}
                className="ClickOkayImage"
                handleGameSwitch={clickYesForEndSession}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Click to Cancel , After clicked a Popup is open to select correct numbers */}
      <Modal isOpen={openCancelPopup} className={"cancelPopup-open"}>
        <Box className="cacel-Popup-box-bg">
          <button
            onClick={() => setOpenCancelPopup(false)}
            className="closePopup"
          >
            <CloseIcon fontSize="20px" />
          </button>
          <p className="WriteMSgPOPup">{modalMessage}</p>
          <Box className="choose-Number-component">
            <Box className="Popup-choosecorrectNumber-BgMega3">
              <p className="PopupEnterNumber">ENTER NUMBER</p>

              <Box className="red-blue-green-popupBox">
                <Box
                  className="popupRedbutton-mega3ball"
                  onClick={() => {
                    handlePopupRedButton();
                  }}
                >
                  {redNumber}
                </Box>
                <Box
                  className="popupGreenbutton-mega3ball"
                  onClick={() => {
                    handlePopupGreenButton();
                  }}
                >
                  {greenNumber}
                </Box>
                <Box
                  className="popupBluebutton-mega3ball "
                  onClick={() => {
                    handlePopupBlurButton();
                  }}
                >
                  {blueNumber}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="popupButtons">
            <CancelPopup
              handlepopupSubmit={handlepopupSubmit}
              Images={SubmitPOPup}
              text="SUBMIT"
              className={"SubmitPopupIMage"}
            />
          </Box>
        </Box>
      </Modal>

      {/*  This Popup is open on the Cacel popup to select red,blue,green numbers */}
      <Modal
        isOpen={enterPopupRedBox || enterPopupGreenBox || enterPopupBlueBox}
        className={"red-green-blue-popupBox"}
      >
        <Box className="red-green-blue-Box-bg">
          <p className="chooseNmuber-redGreen-Blue-button">CHOOSE NUMBER</p>
          <Box className="click-number-box">
            {
              <PointBox
                Images={
                  enterPopupBlueBox
                    ? blueButton
                    : enterPopupRedBox
                    ? redButton
                    : greenButton
                }
                className={"popup6"}
                max={10}
                fontName={"Discoteca Rounded"}
                fontSize={"40px"}
                startIndex={0}
                selectpoint={
                  enterPopupBlueBox
                    ? selectBlueNumber
                    : enterPopupRedBox
                    ? selectRedNumber
                    : selectGreenNumber
                }
              />
            }
          </Box>
        </Box>
      </Modal>

      <Box className="logoutButton3">
        <Box
          className="logoutclick3"
          onClick={() => {
            handleLogout();
          }}
        >
          <Box>Logout</Box>
        </Box>
      </Box>
      <Container maxWidth={false} className="custom-container">
        <Grid container>
          <Grid
            container
            item
            lg={12}
            columnSpacing={3}
            bgcolor={"#00000040"}
            boxShadow={"gold"}
            className="container2"
            marginTop={"50px"}
            paddingBottom=" 40px "
          >
            <Grid item lg={3.7}>
              <img src={imageLogo3} alt="images" className="logo-megaBall-3" />
              <video
                className={"liveVideo"}
                id="liveVideoPlayer"
                controls={true}
                autoPlay={true}
                style={{
                  width: "100%",
                  borderRadius: "15px",
                }}
                onLoadedData={() => {
                  player.current.play();
                }}
                preload="auto"
                playsInline={true}
                webkit-playsinline="true"
              ></video>
              <Box
                className="chatboxOperatorMega3"
                style={{ fontFamily: "Poppins" }}
              >
                <button className="liveOperatorMega3">Live</button>
                <Box className="chatOperatorMega3">
                  {chats?.length > 0 &&
                    chats?.map((chat, i) => (
                      <Box key={i} className="username1">
                        <Box className="emojiMega3">
                          <p className="emojiTag">{chat?.playerName?.split('')[0]}</p>
                          <p className="email">{chat?.playerName}</p>
                          <p className="sentnow">
                            {moment(chat?.dateTime).fromNow()}
                          </p>
                        </Box>
                        <p className="chatting3">{chat?.message}</p>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>

            <Grid item lg={8} position={"relative"}>
              <Box className="timerBoxOperatorMega3">
                <Box className="timerShowBox3">
                  <Box className="minutesSec3"> {minute || ""} </Box>
                  <p className="timerColon">:</p>
                  <Box className="minutesSec3"> {second || ""} </Box>
                </Box>

                <Box className="textTtimerShowBox3">
                  <p>MINUTES</p>
                  <p>SECOND</p>
                </Box>
              </Box>

              <Grid
                bgcolor={"#f4433614"}
                marginTop={"185px"}
                paddingBottom={"100px"}
                position={"relative"}
                paddingTop={"120px"}
                alignItems={"center"}
                borderRadius={"15px"}
                boxShadow={"inset 0 0 5px 1px orange;"}
              >
                <Box
                  display={"flex"}
                  className="ball-container2"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <RoundBall
                    className={"ball-3"}
                    Images={redball}
                    data1={redNumber < 0 ? "" : redNumber}
                    roundBallClass="roundball3"
                  />
                  <RoundBall
                    className={"ball-3"}
                    Images={greenBall}
                    data1={greenNumber < 0 ? "" : greenNumber}
                    roundBallClass="roundball3"
                  />
                  <RoundBall
                    className={"ball-3"}
                    Images={blueBall}
                    data1={blueNumber < 0 ? "" : blueNumber}
                    roundBallClass="roundball3"
                  />
                </Box>
                <Box
                  style={{
                    opacity: sessionDisable || !actionBtnDisable ? "0.5" : "",
                    pointerEvents:
                      sessionDisable || !actionBtnDisable ? "none" : "",
                  }}
                >
                  <h2 className="text">
                    <LineLeftRight Images={lineLeft} className={"lineLeft2"} />
                    1st
                    <LineLeftRight
                      Images={lineRight}
                      className={"lineRight2"}
                    />
                  </h2>

                  <PointBox
                    Images={redButton}
                    className={"cols-9"}
                    fontName={"Discoteca Rounded"}
                    max={10}
                    startIndex={0}
                    selectpoint={selectRedNumber}
                    fontSize={30}
                  />

                  <h2 className="text">
                    <LineLeftRight Images={lineLeft} className={"lineLeft2"} />
                    2nd
                    <LineLeftRight
                      Images={lineRight}
                      className={"lineRight2"}
                    />
                  </h2>

                  <PointBox
                    Images={greenButton}
                    className={"cols-9"}
                    fontName={"Discoteca Rounded"}
                    max={10}
                    startIndex={0}
                    selectpoint={selectGreenNumber}
                    fontSize={30}
                  />

                  <h2 className="text">
                    <LineLeftRight Images={lineLeft} className={"lineLeft2"} />
                    3rd
                    <LineLeftRight
                      Images={lineRight}
                      className={"lineRight2"}
                    />
                  </h2>

                  <PointBox
                    Images={blueButton}
                    className={"cols-9"}
                    fontName={"Discoteca Rounded"}
                    max={10}
                    startIndex={0}
                    selectpoint={selectBlueNumber}
                    fontSize={30}
                  />
                </Box>
              </Grid>
              <Stack
                spacing={5}
                justifyContent={"center"}
                direction="row"
                className="cmdButton"
                style={{
                  opacity: sessionDisable ? "0.5" : "",
                  pointerEvents: sessionDisable ? "none" : "",
                }}
              >
                <ActionButton
                  Images={cancelButton}
                  className={"actionButton"}
                  text="CANCEL"
                  popupScreen={actionCancel}
                  style={{
                    opacity:
                      !paused ||
                      !actionBtnDisable ||
                      sessionDisable ||
                      !submitresults
                        ? "0.5"
                        : "",
                    pointerEvents:
                      !paused ||
                      !actionBtnDisable ||
                      sessionDisable ||
                      !submitresults
                        ? "none"
                        : "",
                  }}
                />
                <ActionButton
                  Images={pauseButton}
                  className={"actionButton"}
                  text={!paused ? "un pause" : "pause"}
                  popupScreen={clickPauseButton}
                  style={{
                    opacity: sessionDisable ? "0.5" : "",
                    pointerEvents: sessionDisable ? "none" : "",
                  }}
                />
                <ActionButton
                  Images={endSession}
                  className={"actionButton"}
                  text={loading == "endSession" ? "Loading..." : "END SESSION"}
                  popupScreen={handleEndSession}
                  style={{
                    opacity:
                      !paused || !submitresults || sessionDisable ? "0.5" : "",
                    pointerEvents:
                      !paused || !submitresults || sessionDisable ? "none" : "",
                  }}
                />
                <ActionButton
                  Images={submit}
                  className={"actionButton"}
                  text={loading == "submit" ? "Loading..." : "SUBMIT"}
                  // popupScreen={actionSubmit}
                  popupScreen={handleSubmitResult}
                  style={{
                    opacity:
                      !paused || !actionBtnDisable || submitresults
                        ? "0.5"
                        : "",
                    pointerEvents:
                      !paused || !actionBtnDisable || submitresults
                        ? "none"
                        : "",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OpareatorMega3Ball;

import { warningMsg } from "../services/helpers";

export async function authMiddleware(fName, payload, token) {
  console.log("authMiddleware", payload, token, "\n HII");
  try {
    let res = await fName(payload, token);
    console.log("RESULT:::::", res);
    return res;
  } catch (error) {
    warningMsg("An unexpected error occured");
    return 
  }
}

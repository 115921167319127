import * as signalR from "@microsoft/signalr";

const Connection = new signalR.HubConnectionBuilder()
  .withUrl(`${process.env.REACT_APP_SignalR_PREFIX}timerhub`, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();

// export function startConnection() {
//   Connection.start()
//     .then(() => {
//       console.log("SignalR Connected");
//     })
//     .catch((err) => {
//       startConnection();
//     });
// }

// startConnection()
Connection.start()
  .then(() => {
    console.log("SignalR Connected");
  })
  .catch((err) => {
    console.log("SignalR Connection Error: ", err);
  });
Connection.onclose((d) => {
  // startConnection()
  console.log("SignalR Connection Error: ", d);
});

export default Connection;

import { Box } from '@mui/material';
import React from 'react';
import ReactModal from 'react-modal';

export const InactiveUser = ({playerStatus}) => {
  return (
    <>
    <ReactModal isOpen={playerStatus} className={"playerStatus userHistory-BackGround"} >
        <Box>
          <span className="underlineHistoryPopup">You cannot play the game</span>
        </Box>
      </ReactModal>
    </>
  )
}

import { Box, Container, Grid, Stack } from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import "../../player/Mega2ball/index.css";
import PointBox from "../../component/PointBox";
import ActionButton from "../../component/ActionButton";
import LineLeftRight from "../../component/LineLeftRight";
import CancelPopup from "../../component/CancelPopup";
import RoundBall from "../../component/RoundBall";
import blueButton from "../../images/Blue_button1.png";
import redButton from "../../images/Red_button1.png";
import redball from "../../images/red_ball1.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blueBall from "../../images/bule_ball1.png";
// import imageLogo2 from "../../images/Mega-2-logo.png";
import imageLogo2 from "../../images/3.png";
import bg from "../../images/bg-1.jpg";
import { useCallback, useEffect, useRef, useState } from "react";
import cancelButton from "../../images/cancel-button.png";
import pauseButton from "../../images/pause-button.png";
import endSession from "../../images/end-session.png";
import submit from "../../images/submit.png";
import lineLeft from "../../images/1st-line-left.png";
import lineRight from "../../images/1st-line-Right.png";
import SubmitPOPup from "../../images/Submit-wo-shadow.png";
import { useNavigate } from "react-router-dom";
import {
  ChatListMega,
  EndGameSession,
  Logout,
  PauseTimer,
  StartTimer,
  SubmitResultForMegaTwoBall,
} from "../../APIHandler/OperatorAPI";
import Connection from "../../services/SignalRConnectionHub";
import {
  endsessionToastifyMsg,
  submitResultForOperatorMega2Ball,
  warningMsg,
} from "../../services/helpers";
import GameSwitchHandler from "../../component/GameSwitchHandler";
import switchGameButtonNo from "../../images/SwitchGame/no-button.png";
import switchGameButtonYes from "../../images/SwitchGame/yes-button.png";
import underlineEndSession from "../../images/InfoScreen/underline.png";
import "../../player/Mega2ball/index.css";
import BeepAudio from "../../Asset/audio/beep.mp3";
import PopupAudio from "../../Asset/audio/Popup.mp3";
import SoftButtonClickAudio from "../../Asset/audio/SoftGameButtonClick.mp3";
import PlayfulMusicalGameEndingAudio from "../../Asset/audio/GameEnding/PlayfulMusicalGameEnding.mp3";
import MagicBonusReveal from "../../Asset/audio/magic_bonus_reveal.mp3";
import CombinationAudio from "../../Asset/audio/Pop02.mp3";
import useSound from "use-sound";
import { cancelRound } from "../../APIHandler/API";
import TCPlayer from "tcplayer.js";
import { useScreenshot } from "use-react-screenshot";
import imageCompression from "browser-image-compression";
import { authMiddleware } from "../../APIHandler/authMiddleware";

//Modal Error Handling , It occures when Popup Modal is open
Modal.setAppElement("#root");

const OpareatorMega2Ball = ({ dateTime }) => {
  let navigate = useNavigate();
  const [tokenAccess, setTokenAccess] = useState(
    localStorage.getItem("tokenOperator") || ""
  );

  const [redButtonData, setRedButtonData] = useState();
  const [blueButtonData, setBlueButtonData] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popupRedNumber, setPopupREDNumber] = useState(false);
  const [popupblueNumber, setPopupblueNumber] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [sessionEndStatus, setsessionEndStatus] = useState(false);

  const [endSessionPopup, setEndSessionPopup] = useState(false);

  const [SessionID, setSessionID] = useState(0);
  const [minutess, setMinutess] = useState("");
  const [secondss, setSecondss] = useState("");

  const [isPaused, setIsPaused] = useState(false);

  const [submitResult, setSubmitResult] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [chats, setchats] = useState([]);
  const [showMute, setShowMute] = useState(
    JSON.parse(localStorage.getItem("showMute"))
  );

  const [playBeepAudio] = useSound(BeepAudio);
  const [playPopupAudio] = useSound(PopupAudio);
  const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
  const [playPlayfulMusicalGameEndingAudio] = useSound(
    PlayfulMusicalGameEndingAudio
  );
  const [playResultRevealAudio] = useSound(MagicBonusReveal);
  const [playCombinationAudio] = useSound(CombinationAudio);

  let x = JSON.parse(localStorage.getItem("showMute"));

  const resultRef = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [loading, setLoading] = useState(""); // State to manage loading
  const [chatViewer, setChatViewer] = useState("CHAT_ONLY");

  const getImage = async (type) => {
    // Set loading to true when the button is clicked
    const capturedImage = await takeScreenshot(resultRef.current);

    const compressedImage = await compressImage(capturedImage);

    // Convert the compressed Blob to Base64
    const base64Image = await blobToBase64(compressedImage);

    if (type == "SUBMIT") handlerSubmit(base64Image);
    else handlepopupSubmit(base64Image); // Call actionSubmit with the captured image
  };
  // Helper function to convert Blob to Base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // This will be the Base64 string
      };
      reader.onerror = reject; // Handle errors
      reader.readAsDataURL(blob); // Convert Blob to Base64
    });
  };

  const compressImage = async (imageDataUrl) => {
    // Convert data URL to Blob
    const response = await fetch(imageDataUrl);
    const blob = await response.blob();

    const options = {
      maxSizeMB: 0.2, // Maximum size in MB
      maxWidthOrHeight: 900, // Maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    return await imageCompression(blob, options);
  };
  if (x !== true && x !== false) {
    localStorage.setItem("showMute", false);
    setShowMute(false);
  }
  const chatList = async () => {
    const response = await ChatListMega({ gameId: 1 });
    if (!response) {
      warningMsg("Error occured while getting chat list");
      setchats(response?.data);
      return;
    }
  };
  useEffect(() => {
    chatList();
  }, [SessionID]);

  //operator Login url
  useEffect(() => {
    if (!tokenAccess) navigate("/operatorLogin");
  }, [tokenAccess]);

  useEffect(() => {
    const startTimerFunc = async () => {
      const res1 = await StartTimer();
      setSessionID(res1?.gameSessionId);
      setMinutess(`${res1?.timer}`?.split(":")[0]);
      setSecondss(`${res1?.timer}`?.split(":")[1]);
      setIsPaused(res1?.timerState === "Play" ? true : false);
      setRedButtonData(
        res1?.result?.result?.[0] < 0 ? "" : res1?.result?.result?.[0]
      );
      setBlueButtonData(
        res1?.result?.result?.[1] < 0 ? "" : res1?.result?.result?.[1]
      );
      if (res1?.result?.message === "Game Session Ended") {
        setSubmitDisable(true);
      }
      setsessionEndStatus(res1?.result?.message === "Game Session Ended");
    };
    startTimerFunc();
    chatList();
  }, []);

  useEffect(() => {
    const RestartTimer = async () => {
      if (minutess === "00" && secondss === "00") {
        console.log("hii");
        const res1 = await StartTimer();
        setSubmitDisable(false);
        setSubmitResult(false);
        setsessionEndStatus(false);
        setModalIsOpen(false);
        setRedButtonData(0);
        setBlueButtonData(0);
        setSessionID(res1?.gameSessionId);
        setEndSessionPopup(false);
        if (res1?.result?.message === "Game Session Ended") {
          setSubmitDisable(true);
        }
        if (!res1) {
          warningMsg("Error occured while starting timer");
          return;
        }
      }
      if (minutess === "00" && secondss !== "00") {
        setSubmitResult(true);
      }
    };
    RestartTimer();
  }, [minutess, secondss]);

  //Connect to the  SignalR
  useEffect(() => {
    Connection.on("timing stop", (message) => {});
    Connection.on("timerUpdate", (message) => {
      setMinutess(`${message}`?.split(":")[0]);
      setSecondss(`${message}`?.split(":")[1]);
      if (message === "01:00") {
        warningMsg("Bet is closed");
        // setAllButtonDisable(true);
      }
      if (message === "01:30") {
        warningMsg("Bet will close soon");
      }
    });
    Connection.off("SendMessageMegaTwo");
    Connection.on("SendMessageMegaTwo", (chatMessage) => {
      setchats((prev) => [...prev, chatMessage]);
    });
  }, []);

  const messageEndRef = useRef(null);
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [chats]);

  useEffect(() => {
    setPopupREDNumber(false);
    setPopupblueNumber(false);
  }, [redButtonData, blueButtonData]);

  const PopupCancel = (value) => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setModalMessage("Please enter the correct result");
    setModalIsOpen(true);
  };

  const selectBlueButtonData = (e) => {
    setBlueButtonData(e);
    if (showMute === false) {
      playCombinationAudio();
    }
  };
  const selectRedButtonData = (e) => {
    setRedButtonData(e);
    if (showMute === false) {
      playCombinationAudio();
    }
  };
  //PauseTimer Api for operator in mega-2 Ball
  const clickPauseTimer = async () => {
    setIsPaused(!isPaused);
    const res2 = await StartTimer();
    const timingPause = {
      timerStoppedAt: `${minutess}:${secondss}`,
      gameId: 1,
      gameSessionId: SessionID,
      timerState: !isPaused ? "Play" : "Pause",
      resumedTime: res2?.resumedTime,
    };
    if (!isPaused == true && submitDisable == false) {
      setRedButtonData("");
      setBlueButtonData("");
    }

    try {
      // const re = await authMiddleware(PauseTimer, timingPause);
      const responses1 = await PauseTimer(timingPause);
      console.log(
        "res2",
        res2,
        "\n responses1",
        responses1,
        res2.gameSessionId != responses1.gameSessionId,
        "heeloo==="
      );
      if (res2.gameSessionId != responses1.gameSessionId) {
        setRedButtonData("");
        setBlueButtonData("");
        window.location.reload();
        const RestartTimer = async () => {
          if (minutess != "00") {
            const res1 = await StartTimer();
            setSubmitDisable(false);
            // setSubmitResult(false);
            setsessionEndStatus(false);
            setModalIsOpen(false);
            setRedButtonData(0);
            setBlueButtonData(0);
            setSessionID(res1?.gameSessionId);
            if (res1?.result?.message === "Game Session Ended") {
              setSubmitDisable(true);
            }
            if (!res1) {
              warningMsg("Error occured while starting timer");
              return;
            }
          }
          if (minutess == "00" && secondss != "00") {
            setSubmitResult(true);
          }
        };
        RestartTimer();
      }
      if (showMute === false) {
        playBeepAudio();
      }
      if (!responses1) {
        warningMsg("Error occured while pausing timer");
        return;
      }
    } catch (error) {
      warningMsg("Error occured while pausing timer");
      return;
    }
  };

  const handlerSubmit = async (capturedImage) => {
    if (redButtonData > 0 && blueButtonData > 0) {
      const submitData = {
        GameSessionId: SessionID,
        Results: [redButtonData, blueButtonData],
        ImageFileBase64: capturedImage,
      };
      try {
        const response = await authMiddleware(
          SubmitResultForMegaTwoBall,
          submitData,
          tokenAccess
        );
        // const response = await SubmitResultForMegaTwoBall(
        //   submitData,
        //   tokenAccess
        // );
        if (showMute === false) {
          playResultRevealAudio();
        }
        if (!response) {
          warningMsg("Error occured while submitting");
          return;
        } else {
          setSubmitDisable(true);
        }
      } catch (error) {
        warningMsg("Error occured while submitting");
        return;
      }
      submitResultForOperatorMega2Ball("Result Submit Successfully");
    } else {
      warningMsg("Please select results");
    }
    setLoading(""); // Set loading to false after the function call is finished
  };

  //submit on popup screen after select red and blue data.
  const handlepopupSubmit = async (image) => {
    const PopupSubmitFunction = {
      GameSessionId: SessionID,
      Results: [redButtonData, blueButtonData],
      ImageFileBase64: image,
    };
    try {
      const resp = await SubmitResultForMegaTwoBall(
        PopupSubmitFunction,
        tokenAccess
      );
      if (resp) {
        setSubmitDisable(true);
      }
      if (showMute === false) {
        playResultRevealAudio();
      }
    } catch (error) {
      warningMsg("Error occured while submitting");
    }
    submitResultForOperatorMega2Ball("Result Submit Successfully");
    setModalIsOpen(false);
    setLoading(""); // Set loading to false after the function call is finished
  };

  const EnterPopupRedNumber = () => {
    if (showMute === false) {
      playCombinationAudio();
    }
    setPopupREDNumber(true);
  };
  const EnterPopupBlueNumber = () => {
    setPopupblueNumber(true);
    if (showMute === false) {
      playCombinationAudio();
    }
  };

  //operator Logout api calling when user click on handleLogout functon
  const handleLogout = async () => {
    let opt = localStorage.getItem("OptId");
    let res = await authMiddleware(Logout, opt);
    console.log("res Logout==", res);
    // let res = await Logout(opt);
    localStorage.removeItem("tokenOperator");
    localStorage.removeItem("tokenOperatorRefresh");
    localStorage.removeItem("OptId");
    navigate("/operatorLogin");
  };
  //CLick End Session popup,  Pop`up will open when operators wants to end the session.
  const clickNoToEndSession = () => {
    if (showMute === false) {
      playSoftButtonClickAudio();
    }
    setEndSessionPopup(false);
  };

  const clickYesToEndSession = async () => {
    setEndSessionPopup(true);
    const SessionEnd = {
      gameId: 1,
      timer: new Date(),
      gameSessionId: SessionID,
      token: tokenAccess,
    };
    try {
      const resp = await authMiddleware(
        EndGameSession,
        SessionEnd,
        tokenAccess
      );
      // const resp = await EndGameSession(SessionEnd, tokenAccess);
      if (showMute === false) {
        playPlayfulMusicalGameEndingAudio();
      }
      // console.log("FDGDG",resp)
      if (resp) {
        setsessionEndStatus(true);
        setEndSessionPopup(false);
        endsessionToastifyMsg("Game session ended successfully");
        setSubmitDisable(true);
      }
    } catch (error) {
      if (showMute === false) {
        playSoftButtonClickAudio();
      }
    }
  };
  //End Session API for operator in mega-2 ball
  const EndSessionMegaTwoBall = async () => {
    setLoading("endSession");
    if (showMute === false) {
      playPopupAudio();
    }
    setEndSessionPopup(true);
    setLoading("");
  };

  const player = useRef();

  useEffect(() => {
    if (player.current) {
      player.current = TCPlayer("liveVideoPlayer", {
        sources: [
          {
            src: process.env.REACT_APP_MEGA2_LIVE_URL,
            // src: 'webrtc://global-lebtest-play.myqcloud.com/live/lebtest?txSecret=f22a813b284137ed10d3259a7b5c224b&txTime=69f1eb8c&tabr_bitrates=d1080p,d540p,d360p&tabr_start_bitrate=d1080p',
          },
        ],
        licenseUrl: process.env.REACT_APP_TCPLAYER_LICENSE_KEY, // License URL, see the preparation section. You can obtain the licenseUrl after applying for a license in the RT-Cube console
        language: "en", // Setting language en | zh-CN
      });
    }

    return () => {
      if (player.current) {
        player.current?.dispose();
      }
    };
  }, []);
  return (
    <Box
      ref={resultRef}
      marginX={"85"}
      style={{
        backgroundImage: `url(${bg})`,
        minHeight: "100vh",
        marginTop: 0,
        backgroundSize: "cover",
        // overflow: "hidden"
      }}
    >
      <ToastContainer />
      {/* Cancel Popup, When operator click on cancel and select again with the help of popup and submit the result  */}
      <Modal isOpen={modalIsOpen} className="showCancel-Popup">
        <Box className="cancel-popup-Bg">
          <button onClick={() => setModalIsOpen(false)} className="closePopup">
            {" "}
            <CloseIcon fontSize="20px" />
          </button>
          <p className="WriteMSgPOPup">{modalMessage}</p>

          <Box className="Popup-ChooseNumber-Bg">
            <p className="PopupEnterNumber">ENTER NUMBER</p>
            <Box className="POPuptimer">
              <button
                className="popupRedbutton"
                onClick={() => {
                  EnterPopupRedNumber();
                }}
              >
                {redButtonData}
              </button>
              <button
                className="popupBluebutton"
                onClick={() => {
                  EnterPopupBlueNumber();
                }}
              >
                {blueButtonData}
              </button>
            </Box>
          </Box>
          <Box className="popupButtons">
            <CancelPopup
              handlepopupSubmit={() => {
                setLoading("cancel");
                getImage("CANCEL_SUBMIT");
              }}
              Images={SubmitPOPup}
              text={loading == "cancel" ? "Loading..." : "SUBMIT"}
              className={"SubmitPopupIMage"}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={popupRedNumber || popupblueNumber}
        onRequestClose={() => {
          setPopupREDNumber(false);
        }}
        className={"chooseNumber-Popup"}
      >
        <Box className="chooseNumber-Box">
          <p className="chooseNmuber">CHOOSE NUMBER</p>

          {
            <PointBox
              Images={popupblueNumber ? blueButton : redButton}
              className={"popup-10"}
              max={38}
              fontSize={"40px"}
              fontName={"Discoteca Rounded"}
              startIndex={1}
              selectpoint={
                popupblueNumber ? selectBlueButtonData : selectRedButtonData
              }
            />
          }
        </Box>
      </Modal>

      {/* End Session Popup, When Operator wants to end the session then popup will open */}

      <Modal isOpen={endSessionPopup} className="showStopTimerAlert">
        <Box className="endGame-session-Bg">
          <button
            onClick={() => {
              setEndSessionPopup(false);
              if (showMute === false) {
                playSoftButtonClickAudio();
              }
            }}
            className="cancelPopup"
          >
            {" "}
            <CloseIcon fontSize="20px" />
          </button>
          <Box className="underlineSwitchGameBox">
            <p>End Session</p>
            <img
              src={underlineEndSession}
              alt="underlineSwitchGame"
              className="underline-endGameSession"
            />
          </Box>
          <Box className="buttonNoYes-endSession-Box">
            <p className="text-endGameBox">
              Do you want to <br></br> end the session?
            </p>
            <Box className="buttonNoYes-endSession">
              <GameSwitchHandler
                Images={switchGameButtonNo}
                text={"No"}
                className="ClickOkayImage"
                handleGameSwitch={clickNoToEndSession}
              />
              <GameSwitchHandler
                Images={switchGameButtonYes}
                text={"Yes"}
                switchTo="3"
                className="ClickOkayImage"
                handleGameSwitch={clickYesToEndSession}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Logout Buttons when operator wants to logout from the game  */}
      <Box className="logoutButton">
        <Box
          className="logoutclick"
          onClick={() => {
            handleLogout();
          }}
        >
          <Box>Logout</Box>
        </Box>
      </Box>

      <Container maxWidth={false} className="custom-container">
        <Grid container>
          <Grid
            container
            item
            lg={12}
            columnSpacing={7}
            bgcolor={"#00000040"}
            marginTop={"60px"}
            boxShadow={" gold"}
            className="container2"
            paddingBottom="22px"
          >
            <Grid item lg={3.7}>
              <img src={imageLogo2} alt="images" className="logo-megaBall-2" />
              <video
                className={"liveVideo"}
                id="liveVideoPlayer"
                controls={true}
                autoPlay={true}
                style={{
                  width: "100%",
                  borderRadius: "15px",
                }}
                onLoadedData={() => {
                  player.current.play();
                }}
                preload="auto"
                playsInline={true}
                webkit-playsinline={true}
              ></video>

              {/* ChatBox , To see the message only by the operator */}
              <Box className="ChattingBox">
                <button className="live1 ">Live</button>
                <Box className="chatbox chatboxScroll">
                  {chats.map((chat, i) => (
                    <Box className="username">
                      <Box className="emoji">
                        <p className="emojiTag">
                          {chat?.playerName?.split("")[0]}
                        </p>
                        <h2 className="mail">{chat?.playerName}</h2>
                        <p className="now">
                          {moment(chat?.dateTime).fromNow()}
                        </p>
                      </Box>

                      <p className="chatting">{chat?.message}</p>
                    </Box>
                  ))}
                  <Box ref={messageEndRef} />
                </Box>
              </Box>
            </Grid>

            <Grid item lg={8} className="operatorMega2ball">
              {/* Timer Show , minutes and seconds */}
              <Box className="timerBoxOperatorMega2">
                <Box className="timerShowBox2">
                  <Box className="minutesSec"> {minutess || ""} </Box>
                  <p className="timerColon">:</p>
                  <Box className="minutesSec"> {secondss || ""} </Box>
                </Box>

                <Box className="textTtimerShowBox2">
                  <p>MINUTES</p>
                  <p>SECOND</p>
                </Box>
              </Box>
              <Grid
                bgcolor={"#f4433614"}
                position={"relative"}
                paddingTop={"30px"}
                marginTop={"156px"}
                alignItems={"center"}
                borderRadius={"15px"}
                marginRight={"20px"}
                boxShadow={"inset 0 0 5px 1px orange;"}
              >
                <Box
                  display={"flex"}
                  className="ball-container"
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap="30px"
                >
                  <RoundBall
                    Images={redball}
                    className={"ball-2"}
                    data1={redButtonData}
                    roundBallClass="roundball"
                  />
                  <RoundBall
                    Images={blueBall}
                    className={"ball-2"}
                    data1={blueButtonData}
                    roundBallClass="roundball"
                  />
                </Box>

                <Box
                  className="Numbring"
                  style={{
                    opacity: sessionEndStatus || submitDisable ? "0.5" : "",
                    pointerEvents:
                      sessionEndStatus || submitDisable ? "none" : "",
                  }}
                >
                  <Box className="heading1">
                    <h2 className="text">
                      <LineLeftRight
                        Images={lineLeft}
                        className={"lineLeft2"}
                      />
                      1st
                      <LineLeftRight
                        Images={lineRight}
                        className={"lineRight2"}
                      />
                    </h2>

                    <PointBox
                      Images={redButton}
                      className={"cols-7"}
                      max={38}
                      fontName={"Discoteca Rounded"}
                      startIndex={1}
                      selectpoint={selectRedButtonData}
                      fontSize={38}
                      disable={!submitResult}
                      style={{
                        opacity: !submitResult ? "0.5" : "",
                        pointerEvents: !submitResult ? "none" : "",
                      }}
                    />
                  </Box>
                  <Box className="heading2">
                    <h2 className="text">
                      <LineLeftRight
                        Images={lineLeft}
                        className={"lineLeft2"}
                      />
                      2nd
                      <LineLeftRight
                        Images={lineRight}
                        className={"lineRight2"}
                      />
                    </h2>

                    <PointBox
                      Images={blueButton}
                      className={"cols-7"}
                      max={38}
                      fontName={"Discoteca Rounded"}
                      startIndex={1}
                      selectpoint={selectBlueButtonData}
                      fontSize={38}
                      disable={!submitResult}
                      style={{
                        opacity: !submitResult ? "0.5" : "",
                        pointerEvents: !submitResult ? "none" : "",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Stack
                spacing={5}
                justifyContent={"center"}
                direction="row"
                className="cmdButton2"
              >
                <ActionButton
                  Images={cancelButton}
                  className={"actionButton"}
                  text="CANCEL"
                  popupScreen={PopupCancel}
                  disable={!submitDisable}
                  // disable={loading == "submit"||loading==""}
                  style={{
                    opacity:
                      !isPaused || !submitDisable || sessionEndStatus
                        ? "0.5"
                        : "",
                    pointerEvents:
                      !isPaused || !submitDisable || sessionEndStatus
                        ? "none"
                        : "",
                  }}
                />
                <ActionButton
                  Images={pauseButton}
                  className={"actionButton"}
                  text={!isPaused ? "UN PAUSE" : "PAUSE"}
                  style={{
                    opacity: sessionEndStatus ? "0.5" : "",
                    pointerEvents: sessionEndStatus ? "none" : "",
                  }}
                  popupScreen={clickPauseTimer}
                />
                <ActionButton
                  Images={endSession}
                  className={"actionButton"}
                  text={loading == "endSession" ? "Loading..." : "END SESSION"}
                  popupScreen={EndSessionMegaTwoBall}
                  style={{
                    opacity:
                      !isPaused || !submitDisable || sessionEndStatus
                        ? "0.5"
                        : "1",
                    pointerEvents:
                      !isPaused || !submitDisable || sessionEndStatus
                        ? "none"
                        : "",
                  }}
                />
                <ActionButton
                  Images={submit}
                  className={"actionButton"}
                  text={loading == "submit" ? "Loading..." : "SUBMIT"}
                  popupScreen={() => {
                    setLoading("submit");
                    getImage("SUBMIT");
                  }}
                  disable={!submitResult || loading == "submit"}
                  style={{
                    opacity:
                      !isPaused || !submitResult || submitDisable ? "0.5" : "1",
                    pointerEvents:
                      !isPaused || !submitResult || submitDisable ? "none" : "",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OpareatorMega2Ball;
//Local

import { Box, Typography } from '@mui/material'
import React from 'react';
import "./index.css"



const index = ({images ,className,text, clickBetButton,disabled,style}) => {


  return (

<Box lg={1} className={className} disabled={disabled}style={style} >
    <img src={images} alt="Images" className='ActionButtonMega3ball actionBall3'  onClick={()=>clickBetButton()}/>
    <Typography
      variant="body2"
      fontFamily={'Bebas'}
       fontSize={22}
      className="Name"  
      style={{pointerEvents:"none"}}   
      sx={{fontSize:{xs:'16px',lg:'22px'}}}  
     >
        {text }
     </Typography>
     
</Box>
  )
}

export default index

import {toast} from 'react-toastify'
// import React, { createContext, useState } from 'react';



export const successMsg = (msg) => toast.success(msg,{
    autoClose:3000,
    toastId:"1",
    position:"top-center",
});
export const errorMsg = (msg) => toast.error(msg,{
    autoClose:3000,
    toastId:"1",
    position:"top-center",
});

export const confirmMSg = (msg) => toast.success(msg,{
    autoClose:3000,
    toastId:"1",
    position:"top-center",
});

export const submitResultForOperatorMega2Ball = (msg) => toast.success(msg,{
    autoClose:2000,
    toastId:"1",
    position:"top-center",
});
export const submitResultForMega3Ball = (msg) => toast.success(msg,{
    autoClose:2000,
    toastId:"1",
    position:"top-center",
});
export const endsessionToastifyMsg = (msg) => toast.success(msg,{
    autoClose:2000,
    toastId:"1",
    position:"top-center",
});
export const submitResultError = (msg) => toast.success(msg,{
    autoClose:2000,
    toastId:"1",
    position:"top-center",
});

export const warningMsg=(message)=> toast.warning(message ||  "Error occured", {
    position:"top-center",
    autoClose:2000,
    toastId:"1",
  });

  export const alertmsg=(message)=> toast.warning(message, {
    position:"top-center",
    autoClose:2000,
    toastId:"1",
  });


import { Box, Grid, Typography } from '@mui/material'
import React from 'react';
import "./index.css"
const index = ({ Images, className, text, handleBet, disabled, style }) => {


  return (

    <Grid item xs={12} md={6} lg={12} className={className} disabled={disabled} style={style} sx={{ maxWidth: { xs: '49%', sm: '50%',md:'100%' } }}>
      <img src={Images} alt="Images" className='ActionButton' sx={{ maxWidth: { xs: '143px', md: '100%' } }} onClick={() => handleBet()} />
      <Typography
        variant="body2"
        fontFamily={'Bebas'}

        sx={{ fontSize: { xs: '10px', sm: '18px', lg: '22px' } }}

        className="Name"
        style={{ pointerEvents: "none", letterSpacing: { xs: '0px', sm: '1px' } }}
      >
        {text}
      </Typography>
    </Grid>
  )
}

export default index

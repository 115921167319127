import React from 'react'
import ReactModal from 'react-modal';
import GameSwitchHandler from '../../../component/GameSwitchHandler';
import PopupAudio from '../../../Asset/audio/Popup.mp3';
import SoftButtonClickAudio from '../../../Asset/audio/SoftGameButtonClick.mp3';
import underlineSwitchGame from "../../../images/InfoScreen/underline.png";
import switchGameButtonNo from "../../../images/SwitchGame/no-button.png";
import switchGameButtonYes from "../../../images/SwitchGame/yes-button.png";
import CloseIcon from "@mui/icons-material/Close";
import useSound from 'use-sound';
import { Box } from '@mui/material';
import "../../../player/Mega2ball/index.css";

const SwitchGame = ({SwitchModalOpen,setSwitchModalOpen,showMute,clickNoToSwitch,clickYesToSwitch,switchTo}) => {
    const [playPopupAudio] = useSound(PopupAudio);
    const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
  return (
    <ReactModal isOpen={SwitchModalOpen} className="show-Popup-EndGamesession"
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
          if (showMute === false) {
            playPopupAudio();
          }
        }}
        onAfterClose={() => {
          document.body.style.overflow = 'unset';
          if (showMute === false) {
            playSoftButtonClickAudio();
          }
        }}>
        <Box className="timerstoppedBox">
          <button className="cancelPopup"
            onClick={() => {
              if (showMute === false) {
                playPopupAudio();
              }
              setSwitchModalOpen(false);
            }}

          >
            {" "}
            <CloseIcon fontSize="20px" />
          </button>
          <Box className="underlineSwitchGameBox">
            <p sx={{ fontSize: { xs: '22px' } }} className="switchGame">Switch Game</p>
            <img
              src={underlineSwitchGame}
              alt="underlineSwitchGame"
              className="underlineSwitchGame"
            />
          </Box>
          <Box className="stoppedTimerpopup2">
            <p className="switchMessageMega3Ball" >
              {" "}
              Do you want to <br></br>switch to Mega {switchTo} Spin?
            </p>
            <Box className="buttonNoYesPopup">
              <GameSwitchHandler
                Images={switchGameButtonNo}
                text={"No"}
                className="ClickOkayImage"
                handleGameSwitch={clickNoToSwitch}
              />
              <GameSwitchHandler
                Images={switchGameButtonYes}
                text={"Yes"}
                className="ClickOkayImage"
                handleGameSwitch={clickYesToSwitch}
              />
            </Box>
          </Box>
        </Box>
      </ReactModal>
  )
}

export default SwitchGame
import { Box } from '@mui/material';
import React, { memo } from 'react'
import ReactModal from 'react-modal';
import SoftButtonClickAudio from '../../../Asset/audio/SoftGameButtonClick.mp3';
import useSound from 'use-sound';
import CloseIcon from "@mui/icons-material/Close";

const HistoryModal = ({ gameHistoryOpen, betFrom, showMute, historyType,gameTitle, setGameHistoryOpen, setHistoryType, todayGameHistory,GameHistoryComponent,PlayerHistoryComponent }) => {
    const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
    
    return (
        <ReactModal isOpen={gameHistoryOpen} className="userHistoryPopupBox"
            onAfterOpen={() => {
                document.body.style.overflow = 'hidden';
                if (showMute === false) { playSoftButtonClickAudio(); }
            }}
            onAfterClose={() => {
                document.body.style.overflow = 'unset'; if (showMute === false) {
                    playSoftButtonClickAudio();
                }
            }}>
            <Box className="userHistory-BackGround">
                <button onClick={() => setGameHistoryOpen(false)} className="cancelPopup">
                    <CloseIcon fontSize="20px" />
                </button>
                <Box display={"flex"} className="historyTabHeadings bgImage ">
                    <Box className={`gameHistoryTab ${historyType ? "active" : ""}`}>
                        <h2 className={`titleTab ${historyType ? "active" : ""}`} onClick={() => setHistoryType(true)}>Game History</h2>
                    </Box>
                    <Box className={`gameHistoryTab ${!historyType ? "active" : ""}`}>
                        <h2 className={`titleTab ${!historyType ? "active" : ""}`} onClick={() => setHistoryType(false)}>Player History</h2>
                    </Box>
                </Box>
                <Box className="userGameHistoryScreen">
                    {/* {historyType && <SameDayGameHistory todayGameHistory={todayGameHistory} />}
                    {!historyType && <PlayerGameHistoryIndex gameTitle={"Mega 2 Ball"} />} */}
                    {/* <PlayerGameHistoryIndex gameTitle={"Mega 2 Ball"} /> */}
                     {historyType && <GameHistoryComponent todayGameHistory={todayGameHistory} />}
                    {!historyType && <PlayerHistoryComponent gameTitle={gameTitle} betFrom={betFrom}/>}
                </Box>
            </Box>
        </ReactModal>
    )
}

export default memo(HistoryModal)
import { Box, Typography } from "@mui/material";
import React from "react";
import "./index.css";
const index = ({
  Images,
  image2,
  className,
  text,
  playerMega2,
  wholeData,
  text2,
  text1,
  text3,
  text4,
  curentInput,
  mega2Img
}) => {
  return (
    <Box xs={12} md={6} lg={12} className={className}>
      <img
        style={{ maxWidth: '100%' }}
        src={Images}
        alt="Images"
        className={`ActionButtonMega2Ball actionBtn ${mega2Img}`}
        sx={{ maxWidth: { xs: '80%', sm: '80%', md: '100%' } ,fontSize:{xs:'12px',sm:'14px',md:'20px',lg:'30px'}}}
        onClick={() => {
          var returnState = false;
          wholeData?.map((item) => {
            if (item?.length < 2) {
              returnState = true;
            }else if(item == null){
              returnState = true;
            }
          });
          if (text === "CLEAR") {
            if (wholeData.length >= 0) {
              playerMega2();
            }
          } else if (text === "LUCKY PICK") {
            if (wholeData.length <= 14||returnState) {
              playerMega2();
            }
          } else {
            playerMega2();
          }
        }}
      />
      <Typography
        variant="body2"
        fontFamily={"Bebas"}
        fontSize={30}
        className="Name"
        style={{ pointerEvents: "none", letterSpacing: "0px" }}
        sx={{ fontSize: { xs: '14px', sm: '25px', md: '20px', lg: '30px' } }}
      >
        {image2 && <img src={image2} alt="" className="image2" />}
        {text}

        <div className="textContainer">
          <span className="text1">{text1}</span>
          <span className="text2" sx={{ fontSize: { xs: '14px', md: '20px', lg: '30px' } }}>{text2}</span>
        </div>
        <div className="textCoin">
          <span className="text3">{text3}</span>
          <span className="text4">{text4}</span>
        </div>
      </Typography>
    </Box>
  );
};

export default index;

import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import "./LoginPage.css";
import { Box } from "@mui/material";
import bg from "../images/bg-1.jpg";
import { generateRefreshToken, Login } from "../APIHandler/OperatorAPI";
import { useNavigate } from "react-router-dom";
import { successMsg, errorMsg, warningMsg } from "../services/helpers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [userType, setUserType] = useState("CHAT_VIEWER");

  let navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (/\s/.test(username) || /\s/.test(password)) {
      warningMsg("Values contain whitespace");
    } else {
      const data = {
        username: username,
        password: password,
      };
      if (username !== "" && password !== "") {
        let res = await Login(data);
        const result = {
          gameId: res?.gameId,
          operatorId: res?.operatorId,
        };
        if (res) successMsg("Login Successfull");
        localStorage.setItem("tokenOperator", res?.token);
        localStorage.setItem("tokenOperatorRefresh", res?.refreshToken);
        localStorage.setItem("OptId", res?.operatorId);
        // ========================
        let tokenTimer = "";
        clearTimeout(tokenTimer);
        tokenTimer = setTimeout(async function () {
          let payload = {
            token: localStorage.getItem("tokenOperator"),
            refreshToken: localStorage.getItem("tokenOperatorRefresh"),
            userName: username,
            password: password,
          };
          //call refresh token api
          let resultRefresh = await generateRefreshToken(payload);
          console.log(
            { resultRefresh },
            resultRefresh?.token,
            resultRefresh?.refreshToken
          );
          localStorage.setItem("tokenOperator", resultRefresh?.token);
          localStorage.setItem(
            "tokenOperatorRefresh",
            resultRefresh?.refreshToken
          );
        }, 1000 * 60 * 60);
        // ========================
        let x = JSON.parse(localStorage.getItem("showMute"));
        if (x !== true && x !== false) {
          localStorage.setItem("showMute", false);
        }
        if (rememberMe === true) {
          localStorage.setItem("op_email", username);
          localStorage.setItem(
            "op_pass",
            CryptoJS.AES.encrypt(
              JSON.stringify(password),
              process.env.REACT_APP_SECRET_KEY
            ).toString()
          );
        } else {
          localStorage.removeItem("op_email");
          localStorage.removeItem("op_pass");
        }
        if (userType == "CHAT_VIEWER" && res?.operatorId === 2) {
          navigate("/chatViewer/mega2spin", { state: result });
        } else if (userType == "CHAT_VIEWER" && res?.operatorId === 3) {
          navigate("/chatViewer/mega3spin", { state: result });
        }
        if (res?.operatorId === 3) {
          navigate("/operator/mega3spin", { state: result });
        } else if (res?.operatorId === 2) {
          navigate("/operator/mega2spin", { state: result });
        } else {
          errorMsg("Login Failed");
        }
      } else {
        warningMsg("Please provide a valid input");
      }
    }
  };
  const rememberMeFunction = (value) => {
    setRememberMe(value);
  };
  useEffect(() => {
    if (localStorage.getItem("op_email")) {
      let ciphertext = localStorage.getItem("op_pass");
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(
        ciphertext,
        process.env.REACT_APP_SECRET_KEY
      );
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setPassword(decryptedData);
      setUsername(localStorage.getItem("op_email"));
    } else {
      setPassword("");
      setUsername("");
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <Box
        style={{
          backgroundImage: `url(${bg})`,
          paddingLeft: "50px",
          paddingRight: "50px",
          paddingTop: "55px",
          paddingBottom: "40px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Box
          container
          bgcolor={"#00000040"}
          display={"flex"}
          justifyContent={"center"}
          minHeight={"100%"}
          paddingY={"10px"}
        >
          <FormControl style={{ alignSelf: "center" }}>
            <Box className="loginBox">
              <form onSubmit={handleSubmit}>
                <p className="loginHeading">Login</p>
                <label>
                  <input
                    className="pagination"
                    type="email"
                    id="emailInput"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setErrorMessage("");
                    }}
                  />
                </label>
                <label>
                  <input
                    className="pagination"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrorMessage("");
                    }}
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                  }}
                >
                  <label>
                    <input
                      type="checkbox"
                      value={rememberMe}
                      onChange={() => rememberMeFunction(!rememberMe)}
                    />
                    Remember Me
                  </label>
                </div>
                <input type="submit" value="sign in" className="confirmation" />
                <div style={{ color: "red", margin: "10px" }}>
                  {errorMessage}
                </div>
              </form>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}

export default LoginPage;

import { Box, Typography } from "@mui/material";
import React from "react";
import "./index.css";
const Index = ({ Images, image2, payoutTotal, className, text, text2, text1, text3, text4, text5, text6, text9, text10, text11, style, sx, text2sx, ActionButtonMega2Ball }) => {

  return (
    <Box  lg={1} className={className} style={style} sx={sx}>
      <img src={Images} alt="Images"
        // style={{maxWidth:'100%'}} 
        className={ActionButtonMega2Ball} />
      <Typography
        variant="body2"

        sx={{ fontSize: { xs: '14px', sm: '18px', lg: '22px' },width:"100%" }}
        className="Name"
      >
        {image2 && <img src={image2} alt="" className="image2" />}
        {text}

        {text1 && <div className="textContainer">
          <span className="text1"  >{text1}</span>
          <span className="text2" sx={text2sx}>{text2}</span>
          <span className="text9">{text9}</span>
        </div>}
        {text3 && <div className="textCoin">
          <span className="text3">{text3}</span>
          <span className="text4">{text4}</span>
        </div>}
       {text5 && <div className="straightPayoutText">
          <span className="text5" >{text5}</span>
          <div className="text6" style={{display: "flex",alignItems:"center"}}><span className="text6">{payoutTotal}</span><span className="text6">:</span><span className="text6">{text6}</span></div>
        </div>}
        {text11 && <div className="creditPoint-PlayerMega3" style={{width:"100%"}}>
          <span className="text11">{text11}</span>
          <div className="text10">{text10}</div>

        </div>}
      </Typography>
    </Box>
  );
};

export default Index;

import { Box, Typography } from "@mui/material";
import "./index.css";
import LineLeftRight from "../LineLeftRight"

const Index = ({ Images, max, className, fontName, startIndex, selectpoint, fontSize, disabled, lineRight, lineLeft, title,sx,disable,style }) => {
  return (
    <>
      {title && <Box>
        <h5 className="numbringPlayerButton">
          <LineLeftRight
            Images={lineLeft}
            className={"lineLeftPlayer"}
          />
          {title}
          <LineLeftRight
            Images={lineRight}
            className={"lineRightPlayer"}
          />
        </h5>
      </Box>}
      <Box className={className} sx={sx} display={"flex"} justifyContent={"center"} flexWrap={'wrap'} disabled={disabled} style={style}> {[...new Array(max)].map((a, i) => {
        return <Box display={"flex"} className="NumberBox" sx={{        margin: {xs:"2px",sm:"2px 0px"}}} key={i} onClick={() => !disabled && selectpoint(i + startIndex)}>
          <img src={Images} alt="Images" width={"100%"} height={"100%"} />
          <Typography
            variant="body2"
            padding={'20px 20px'}
            fontFamily={fontName}
            className="NumberShow"
            fontSize={fontSize}
            sx={{ fontSize: { xs: '18px !important', md: '30px !important', lg: '30px !important' } }}
          >
            {i + startIndex}
          </Typography>

        </Box>
      })}
      </Box>
    </>
  );
}

export default Index;

import React from 'react';
import ReactModal from 'react-modal';
import congratulationStartBg from '../../../images/resultPopup/shadow-for-congrats-popup.png';
import congratulationStartImg from '../../../images/resultPopup/congratulation-pop-up.png';
import { Box } from '@mui/material';
import "../../../player/Mega2ball/index.css";

export default function WinnerModal({ congratsShowHide, totalBetAmount }) {
  return (
    <ReactModal
            isOpen={congratsShowHide}
            onAfterOpen={() => document.body.style.overflow = 'hidden'}
            onAfterClose={() => document.body.style.overflow = 'unset'}
            className={"result-congratulation-popup"}
        >
            <img src={congratulationStartBg} alt="congratulation" className={"congratulationBg"} />
            <Box className="result-congratulation-popup-bg">
                <Box className="congratulationStartImg-box" sx={{ top: { xs: '-77px', lg: '-252px' } }}>
                    <img src={congratulationStartImg} alt="congratulation" className={"congratulationStartImg"} />
                </Box>
                <Box className="congratulation-text-box">
                    <p className="congratulation-text" sx={{ fontSize: { xs: '46px', lg: '66px' } }} >You Won</p>
                </Box>
                <Box className="show-winAmount">
                    <p className="show-winAmount-box">
                        <span className="p-doller">₱</span> {totalBetAmount}
                    </p>
                </Box>
            </Box>
        </ReactModal>
  )
}

import { Box, Divider } from '@mui/material'
import React from 'react'

const GameHistoryMega3 = ({todayGameHistory}) => {
    return (<>
        {todayGameHistory?.length > 0 &&
            todayGameHistory.map((a, i) => (
                <>
                    <Box key={i} className="historyBlock">
                        <p className="numbringPlayerTwoHistory" sx={{ fontSize: { xs: '14px', sm: '16px', md: '20px', lg: '24px' } }}>
                            {i + 1 === 1
                                ? "1st"
                                : i + 1 === 2
                                    ? "2nd"
                                    : i + 1 === 3
                                        ? "3rd"
                                        : `${i + 1}th`}
                        </p>
                        <Box className="PlayerhistoryPopupBoxRed" sx={{
                            fontSize: { xs: '14px', sm: '16px', md: '20px', lg: '24px' },
                            width: { xs: '24px', sm: '35px', md: '45px', lg: '60px' },
                            height: { xs: '20px', sm: '31px', md: '41px', lg: '50px' }
                        }}>{a?.history?.[0]?.[0]}</Box>
                        <Box className="PlayerhistoryPopupBoxGreen" sx={{
                            fontSize: { xs: '14px', sm: '16px', md: '20px', lg: '24px' },
                            width: { xs: '24px', sm: '35px', md: '45px', lg: '60px' },
                            height: { xs: '20px', sm: '31px', md: '41px', lg: '50px' }
                        }}>
                            {a?.history?.[0]?.[1]}
                        </Box>
                        <Box className="PlayerhistoryPopupBoxBlue" sx={{
                            fontSize: { xs: '14px', sm: '16px', md: '20px', lg: '24px' },
                            width: { xs: '27px', sm: '38px', md: '46px', lg: '60px' },
                            height: { xs: '24px', sm: '35px', md: '43px', lg: '50px' }
                        }}>
                            {a?.history?.[0]?.[2]}
                        </Box>
                    </Box>
                    {(i + 1) % 7 === 0 ? <Divider /> : null}
                </>
            ))}
    </>
    )
}

export default GameHistoryMega3
import { Box, Typography } from '@mui/material'
import React from 'react';


const index = ({ Images, className, text, handleGameSwitch }) => {


  return (

    <Box lg={1} className={className}   >
      <img src={Images} alt="Images" className='ActionButton' onClick={() => handleGameSwitch()} />
      <Typography
        variant="body2"
        fontFamily={'Bebas'}
        fontSize={30}
        className="Name"
        letterSpacing='1px'
        style={{ pointerEvents: "none" }}
        sx={{ fontSize: { xs: '14px', md: '18px',lg:'30px' } }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default index

import { Box, Typography } from '@mui/material'
import React from 'react';
import "./index.css"

const index = ({Images ,className,data1,roundBallClass,disable,style}) => {

  return (

<Box  className={className}   >

    <img src={Images} alt="Images" className={roundBallClass}  />

    <Typography
      variant="body2"
       fontSize={45}
       color={"black"}
       fontWeight={"bold"}
      className="RoundNumber"
           sx={{fontSize:{xs:'23px',md:'40px'}}}
           disable={disable}
           style={style}
     >
       {data1} 
     </Typography>
</Box>
  )
}

export default index

import { Box, Typography } from '@mui/material'
import React from 'react'

const index = ({Images,text,className,handlepopupSubmit}) => {
  return (
    <Box className={className} onClick ={()=>{handlepopupSubmit()}}>
      <img src={Images} alt="Images" />
    <Typography 
     className="Name"
     fontFamily={'Bebas'}
     fontSize={22}
      >
        {text}
    </Typography>

    </Box>
  )
}

export default index

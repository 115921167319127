import { Box, Typography } from "@mui/material";
import "./index.css";

const Index = ({ Images, max, className, fontName, startIndex, selectNumber, fontSize, disabled, disableFirstRamblito3, disableSecondRamblito3 }) => {
  // console.log({ disableFirstRamblito3, disableSecondRamblito3, });
  return (
    <>
      <Box className={className} display={"flex"} justifyContent={"center"} gap={"5px"} disabled={disabled}>
        {[...new Array(max)].map((a, i) => {
          return <>
            <Box display={"flex"} className="NumberBox" onClick={() => !disabled && selectNumber(i + startIndex)} style={{
              opacity: ((disableSecondRamblito3 === disableFirstRamblito3 && disableFirstRamblito3 === i) && disableFirstRamblito3 > -1) ? "0.7" :
                disableFirstRamblito3 > -1 && disableSecondRamblito3 > -1 && disableFirstRamblito3 != disableSecondRamblito3 ?
                  (i === disableFirstRamblito3 || disableSecondRamblito3 === i) ? "" : "0.7"
                  :
                  "",
              pointerEvents: ((disableSecondRamblito3 === disableFirstRamblito3 && disableFirstRamblito3 === i) && disableFirstRamblito3 > -1) ? "none" :
                disableFirstRamblito3 > -1 && disableSecondRamblito3 > -1 && disableFirstRamblito3 != disableSecondRamblito3 ?
                  (i === disableFirstRamblito3 || disableSecondRamblito3 === i) ? "" : "none"
                  :
                  "",
            }}

            >
              <img src={Images} alt="Images" width={"95%"} height={"95%"} />
              <Typography
                variant="body2"
                padding={'20px 20px'}
                fontFamily={fontName}
                className="NumberShow"
                fontSize={fontSize}

              >
                {i + startIndex}
              </Typography>
            </Box>
          </>
        })}
      </Box>
    </>
  );
}

export default Index;

import { Box } from '@mui/material';
import React from 'react'
import Modal from "react-modal";
import PopupButtonsHandler from "../../component/PopupButtonsHandler";


const EndSession = ({clickOkImages,endGameSession,setEndGameSession}) => {
  return (
    <>
        <Modal isOpen={endGameSession} className="showStop-endSessionPopup" onAfterOpen={() => document.body.style.overflow = 'hidden'}
            onAfterClose={() => document.body.style.overflow = 'unset'}>
            <Box className="endSession-Popup-bg">
              <Box className="endSession-Popup-text">
                The gameSession has been end !
              </Box>
              <Box className="button-Okay-endSessionPopup ">
                <PopupButtonsHandler
                  Images={clickOkImages}
                  text={"Okay"}
                  className="ClickOkayImage"
                  clickOkayPopup={() => setEndGameSession(false)
                  }
                />
              </Box>
            </Box>
          </Modal>
    </>
  )
}

export default EndSession;

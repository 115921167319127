import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { Typography } from "@mui/material";

const headCells = [
  {
    id: "betType",
    numeric: false,
    disablePadding: true,
    label: "Bet Type",
    display: { xs: "none", md: "table-cell" },
  },
  {
    id: "placedBetNumber",
    numeric: false,
    disablePadding: false,
    label: "Placed Bet Number",
    display: { xs: "none", md: "table-cell" },
  },
  {
    id: "betAmount",
    numeric: true,
    disablePadding: false,
    label: "Bet Amount",
    display: { xs: "none", md: "table-cell" },
  },
  {
    id: "winAmount",
    numeric: true,
    disablePadding: false,
    label: "Winning Amount",
    display: { xs: "none", md: "table-cell" },
  },
  {
    id: "result",
    numeric: true,
    disablePadding: false,
    label: "Result",
    display: { xs: "none", md: "table-cell" },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    display: { xs: "none", md: "table-cell" },
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            style={{ color: "#44eeff", fontWeight: "bold" }}
            sx={{
              fontSize: { xs:"0.8rem", md: "1rem" },
              padding: { xs: "0px 16px", md: "16px" },
              display: headCell?.display,
            }}
          >
            {headCell.label}
            <Box component="span" sx={visuallyHidden}></Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableSection({ item, RoyalBet }) {
  return (
    <Box sx={{ width: "100%", color: "#fff" }} className="bgImage">
      <TableContainer>
        <Table
          // style={{overflowY:"hidden",overflowX:"auto"}}
          sx={{
            minWidth: 750,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead />
          <TableBody>
            {[item].map((row, index) => {
              if (RoyalBet == 2) {
                return (
                  <>
                    <TableRowData
                      RoyalBet={RoyalBet}
                      name="Straight Bet"
                      row={row}
                      betType="streghtBet"
                    />
                    <TableRowData
                      RoyalBet={RoyalBet}
                      name="Rumble Bet"
                      row={row}
                      betType="rumbelBet"
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <TableRowData
                      RoyalBet={RoyalBet}
                      name="Straight Bet"
                      row={row}
                      betType="straightBet"
                    />
                    <TableRowData
                      RoyalBet={RoyalBet}
                      name="Rambolito3 Bet"
                      row={row}
                      betType="rambolitoThreeBet"
                    />
                    <TableRowData
                      RoyalBet={RoyalBet}
                      name="Rambolito6 Bet"
                      row={row}
                      betType="rambolitoSixBet"
                    />
                  </>
                );
              }
            })}

{/* for Mobile below MD Size */}

            <TableSXRow
              display={{ md: "none" }}
              item={item}
              keyName="Bet Amount"
              valueName={
                <Box sx={{display:{xs:"flex"},padding:"0px 16px"}}>
                  <Typography
                    variant="body2"
                    style={{ padding: "0px 5px 0px 0px" }}
                    sx={{ fontSize: { xs:  "0.8rem", md: "1rem" } }}
                  >
                    ₱
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: { xs:  "0.8rem", md: "1rem" } }}>{item?.totalBetAmount}</Typography>
                </Box>
              }
            />
            <TableSXRow
              display={{ md: "none" }}
              item={item}
              keyName="Winning Amount"
              valueName={
                <Box sx={{display:{xs:"flex"},padding:"0px 16px"}}>
                  <Typography
                    variant="body2"
                    style={{ padding: "0px 5px 0px 0px" }}
                    sx={{ fontSize: { xs:  "0.8rem", md: "1rem" } }}
                  >
                    ₱
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: { xs:  "0.8rem", md: "1rem" } }}>{item?.winningAmount}</Typography>
                  
                </Box>
              }
            />
            <TableSXRow
              display={{ md: "none" }}
              item={item}
              keyName="Result"
              valueName={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={`bet_Number`} style={{padding:"0px 16px"}}>
                    {[...new Array(RoyalBet)].map((e, i) => {
                      // console.log("first:::", item);
                      return (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: { xs:  "0.8rem", md: "1rem" },
                          }}
                        >
                          {item?.result[i]},{" "}
                        </Typography>
                      );
                    })}
                    {/* <p style={{fontSize:"18px"}}>{array[index + 1]}</p> */}
                  </div>
                </div>
              }
            />
            <TableSXRow
              display={{ md: "none" }}
              item={item}
              keyName="Status"
              valueName={item?.status ? "WIN" : "LOSS"}
              color1={item?.status? "rgb(12, 227, 12)":"#d3202b"}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const TableSXRow = ({ item, keyName, valueName, display, color1 }) => {
  return (
    <TableRow key={item[0]?.id} sx={{ display: display }}>
      <TableCell
        align={"left"}
        style={{ color: "#44eeff"}}
        sx={{
          fontSize: { xs: "0.8rem", md: "1rem" },
          padding: { xs: "1px", md: "16px" },
          width:{xs:color1?"100px":"0px"}
        }}
      >
        {keyName} 
      </TableCell>
      <TableCell
        style={{ color: color1? color1:"#fff" }}
        sx={{
          fontSize: { xs:  "0.8rem", md: "1rem" },
          padding: { xs: "16px 0px", md: "16px" },
          fontWeight:{xs:900}
        }}
        component="th"
        scope="row"
      >
        {valueName}
      </TableCell>
    </TableRow>
  );
};

const TableRowData = ({ name, row, betType, RoyalBet }) => {
  const [viewStatus,setViewStatus] = React.useState(false)
  return (
    <TableRow key={row?.id}>
      <TableCell
        style={{ color: "#44eeff" }}
        sx={{
          width:{xs:"100px"},
          fontSize: { xs:  "0.8rem", md: "1rem" },
          padding: { xs: "1px", md: "16px" },
        }}
        component="th"
        scope="row"
      >
        {name}
      </TableCell>
      <TableCell style={{ color: "#fff" }} align="left">
        <div className="betType_box" style={{ alignItems: "center" }}>
          <RenderArrayNumber
            array={row[betType]?.bets?.flat(Infinity)}
            step={RoyalBet}
            betType={betType}
            matchArray={row?.result}
            setViewStatus={setViewStatus}
          />
        </div>
      </TableCell>
      <TableCell
        sx={{ display: { xs: "none", md: "table-cell" } }}
        style={{ color: "#fff" }}
        align="left"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            // style={{  padding: "0px 5px 0px 0px" }}
            sx={{ fontSize: { xs:  "0.8rem", md: "1rem" },width:{xs:"40px",md:"10px"} }}
          >
            ₱
          </Typography>
          <Typography variant="subtitle1" sx={{ px: 1 ,width:{xs:"40px",md:"10px"}}} >
            {row?.[betType]?.betAmount} 
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{ display: { xs: "none", md: "table-cell" } }}
        style={{ color: "#fff" }}
        align="left"
      >
        <Box style={{ display: "flex", alignItems: "center" }} >
          <Typography
            variant="body2"
            sx={{ fontSize: { xs:  "0.8rem", md: "1rem" },width:{xs:"40px",md:"10px"} }}
          >
            ₱
          </Typography>
          <Typography variant="body2" sx={{ px: 1,fontSize: { xs:  "0.8rem", md: "1rem" }, width:{xs:"40px",md:"10px"} }}>
            {viewStatus ? row?.winningAmount : "-"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={{ display: { xs: "none", md: "table-cell" } }}
        style={{ color: "#fff" }}
        align="left"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <RenderArrayNumber
            show={row[betType]?.bets?.length == 0 ? false : true}
            array={row?.result}
            step={RoyalBet}
            betType={betType}
          />
        </div>
      </TableCell>
      <TableCell
        style={{ color: viewStatus ? "#0ce30c" : "#ffff", fontWeight: "bold" }}
        sx={{
          display: { xs: "none", md: "table-cell" },
          fontSize: { xs:  "0.8rem", md: "1rem" },
        }}
        align="left"
      >
        {viewStatus ? "Win" : "-"}
      </TableCell>
    </TableRow>
  );
};

const RenderArrayNumber = ({ array, step, matchArray, betType,setViewStatus,show=true }) => {
  if (!array) {
    return;
  }

  const activeResult3 = (array, matchArray, index) => {
    if (betType == "straightBet") {
      let result =   (
        array[index] == matchArray[0] &&
        array[index + 1] == matchArray[1] &&
        array[index + 2] == matchArray[2]
      );
      if(result){
        setViewStatus((prev) => {
          if(!prev){
            return true
          }
          return prev;
        })
      }
      return result;
    } else {
      let fistArrayMatch = [
        ...[array[index], array[index + 1], array[index + 2]],
      ].sort();
      let secArrayMatch = [...matchArray].sort();
      let finalRes = [
        ...new Set(fistArrayMatch.map((e,i) => secArrayMatch[i] == fistArrayMatch[i])),
      ];
      let result = finalRes?.length == 1 && finalRes[0] == true;
      // console.log(fistArrayMatch,secArrayMatch,result, "rrr", finalRes,fistArrayMatch.map((e) => secArrayMatch.includes(e)));
      if(result){
        setViewStatus((prev) => {
          if(!prev){
            return true
          }
          return prev;
        })
      }
      return result;
    }
  };

  const activeResult2 = (array, matchArray, index) => {
    if (betType == "straightBet") {
      let result =  array[index] == matchArray[0] && array[index + 1] == matchArray[1];
      if(result){
        setViewStatus((prev) => {
          if(!prev){
            return true
          }
          return prev;
        })
      }
      return result
    } else {
      let fistArrayMatch = [...[array[index], array[index + 1]]].sort();
      let secArrayMatch = [...matchArray].sort();
      let finalRes = [
        ...new Set(fistArrayMatch.map((e) => secArrayMatch.includes(e))),
      ];
      let result = finalRes?.length == 1 && finalRes[0] == true;
      // console.log(result, "rrr", finalRes);
      if(result){
        setViewStatus((prev) => {
          if(!prev){
            return true
          }
          return prev;
        })
      }
      return result;
    }
  };

  const elements = []; // Initialize an array to store the JSX elements

  for (let index = 0; index < array.length; index += step) {
    elements.push(
      <div
        className={`bet_Number ${
          matchArray
            ? step == 2
              ? activeResult2(array, matchArray, index)
                ? "active"
                : ""
              : activeResult3(array, matchArray, index) == true
              ? "active"
              : ""
            : ""
        }`}
      >
        {show ? [...new Array(step)].map((e, i, s) => (
          <Typography
            variant="subtitle2"
            sx={{ fontSize: { xs:  "0.8rem", md: "1rem" } }}
          >
          
            {array[index + i]}{s?.length > i + 1 ? "," : ""}
            
          </Typography>
        )) : ""}
        {/* <p style={{fontSize:"18px"}}>{array[index + 1]}</p> */}
      </div>
    );
  }
  return elements;
};

import { Box, Typography } from '@mui/material'
import React from 'react';
import "./index.css"
// import redball from "../../images/Red-ball.png";


const index = ({ Images, className, text, clickOkayPopup, style }) => {


  return (

    <Box lg={1} className={className}    >

      <img src={Images} alt="Images" className='ClickOkayImage' style={{ cursor: 'pointer' }} onClick={() => clickOkayPopup()} />
      <Typography
        variant="body2"
        fontFamily={'Bebas'}
        fontSize={20}
        letterSpacing='1px'
        className="buttonName"
        style={{ pointerEvents: "none",...style}}
        sx={{fontSize:{xs:"14px",md:"18px",lg:"30px"}}}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default index

import { Box } from '@mui/material';
import React from 'react'
import Modal from "react-modal";
import PopupButtonsHandler from "../../component/PopupButtonsHandler";



const TimerPopup = ({clickOkPaused,clickOkImages,resumedTimerPopup}) => {
  return (
    <>
        <Modal isOpen={resumedTimerPopup} className="showStopTimerPopup"
            onAfterOpen={() => document.body.style.overflow = 'hidden'}
            onAfterClose={() => document.body.style.overflow = 'unset'}>
            <Box className="timerstoppopup">
              <Box className="stoppedTimer2"> The game has been resumed !</Box>
              <Box className="buttonOkay">
                <PopupButtonsHandler
                  Images={clickOkImages}
                  text={"Okay"}
                  className="ClickOkayImage"
                  clickOkayPopup={clickOkPaused}
                />
              </Box>
            </Box>
          </Modal>
    </>
  )
}

export default TimerPopup
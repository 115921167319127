import React from "react";
import ReactModal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import gameRulesUndarline from "../../../images/InfoScreen/underline.png";
import GameSwitchhandler from "../../../component/GameSwitchHandler";
import { Box } from "@mui/material";

const RulesModal = ({
  gameInfo,
  setGameInfo,
  clickGameRules,
  clickPayoutInfo,
  showBox,
  okayButtonInfoPayout,
  clickOkayInfoScreen,
}) => {
  return (
    <ReactModal
      isOpen={gameInfo}
      className={"gameHistory-payout-popup"}
      sx={{ marginTop: { xs: "66px" } }}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
    >
      <Box
        className="gameHistory-payout-box-bg"
        sx={{
          width: { xs: "95%", lg: "960px" },
          // height: { xs: '710px', sm: '620px' }
        }}
      >
        <button
          onClick={() => setGameInfo(false)}
          className="closePopupMega2"
          sx={{ height: { xs: "50px" }, width: { xs: "50px" } }}
        >
          <CloseIcon fontSize="20px" />
        </button>
        <Box className="RulesAndPayout-Mega2Ball-Box">
          <Box className="gameRules-Mega2Ball" onClick={() => clickGameRules()}>
            <p style={{ color: !(showBox === "gameRuleBox") ? "grey" : "#fff" }}>
              Game Rules
            </p>
            <Box
              className="gameRulesUndarline"
              sx={{
                margin: { xs: "-25px 0 0 -15px", lg: "-41px 0px 0px -20px" },
              }}
            >
              {showBox === "gameRuleBox" && (
                <img src={gameRulesUndarline} alt="gameRulesUndarline" />
              )}
            </Box>
          </Box>
          <Box className="payout-Mega2Ball" onClick={() => clickPayoutInfo()}>
            <p style={{ color: (showBox === "gameRuleBox") ? "grey" : "#fff" }}> Payout</p>
            <Box
              className="gameRulesUndarline"
              sx={{
                margin: { xs: "-25px 0 0 -15px", lg: "-41px 0px 0px -12px" },
              }}
            >
              <img
                src={gameRulesUndarline}
                alt="gameRulesUndarline"
                style={{
                  visibility: !(showBox === "gameRuleBox")
                    ? "visible"
                    : "hidden",
                }}
              />
            </Box>
          </Box>
        </Box>
        {showBox === "gameRuleBox" && (
          <Box className="gameRuleBox">
            <Box sx={{ marginY: "1rem" }}>
              <Box
                className="betTypesText"
                sx={{ fontSize: { xs: "16px", md: "20px", lg: "26px" } }}
              >
                {" "}
                Bet Types:{" "}
              </Box>
              <Box className="betTypes-Mega2Ball">
                <ul>
                  <li>
                    Straight Bet: You win if wheel is drawn in exact order.
                  </li>
                  <li>Rumble Bet: You win if wheel is drawn in any order.</li>
                </ul>
              </Box>
              <Box
                className="howToPlay-Text"
                sx={{ fontSize: { xs: "16px", md: "20px", lg: "26px" } }}
              >
                {" "}
                How to play:{" "}
              </Box>
              <Box className="howToPlay-Mega2Ball">
                <ul>
                  <li>
                    Pick a 2-number combination from a field of 1 to 38 <br />
                    (1 from red and 1 from blue).
                  </li>
                  <li>
                    You may also click on the 'Lucky Pick' button if you <br />
                    do not have any number in mind.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li>
                    After picking your numbers, set the amount you want to bet
                    for each bet type. The minimumbe bet is 1.
                  </li>
                  <li> Click 'Confirm' to lock in your bets.</li>
                </ul>
              </Box>
            </Box>
          </Box>
        )}
        {showBox === "payoutBox" && <Box className="payoutBox"></Box>}
        <Box className="okayButton-Info-Payout-Box">
          <GameSwitchhandler
            Images={okayButtonInfoPayout}
            className={"actionButtonOkay"}
            text={"okay"}
            handleGameSwitch={clickOkayInfoScreen}
          />
        </Box>
      </Box>
    </ReactModal>
  );
};

export default RulesModal;

import { Backdrop } from "@mui/material";
import React, { useEffect, useState } from "react";
import Portrait from "../../Asset/mobile/Flip-mobile-portrait (002).gif"
import Landscape from "../../Asset/mobile/Flip-mobile-landscape (002).gif"


  export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
function BackdropRotate({variant}) {
  const [rotateDeviceStatus, setrotateDeviceStatus] = useState(false);

  useEffect(() => {
    let verticalDetect = variant == "portrait" ?  window.matchMedia("(orientation: portrait)").matches : !window.matchMedia("(orientation: portrait)").matches;
    // console.log("verticalDetect", verticalDetect, isMobile.any());
    if (isMobile.any() && verticalDetect) {
      setrotateDeviceStatus(true);
    } else {
      setrotateDeviceStatus(false);
    }
  }, []);

  window.addEventListener("orientationchange", (event) => {
    let verticalDetect = event.target.screen.orientation.angle;
    if(variant == "portrait"){
      if (isMobile.any() && verticalDetect == 0) {
        setrotateDeviceStatus(true);
      } else {
        setrotateDeviceStatus(false);
      }
    }else{
      if (isMobile.any() && verticalDetect == 0) {
        setrotateDeviceStatus(false);
      } else {
        setrotateDeviceStatus(true);
      }
    }
  });
  return (
    <Backdrop
      sx={{
        color: "#fff",
        backgroundColor: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={rotateDeviceStatus}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <ScreenRotationIcon color="inherit" style={{width:"30%", height:"30%"}} /> */}
       {/* <video
          width={320}
          height={240}
          src="https://static.vecteezy.com/system/resources/previews/040/466/642/mp4/rotate-your-phone-animation-motion-2d-animation-isolated-on-transperancy-background-or-alpha-channel-free-video.mp4"
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        >
        </video>  */}
       { variant == "portrait" ? 
       <img src={Portrait} alt="Rotate your phone" />
        : <img src={Landscape} alt="Rotate your phone" />
        }
        {/* <video
          width={400}
          height={440}
          src="https://v.ftcdn.net/06/35/69/53/700_F_635695393_4kFbSgeIbo6hd3ugIIUVcHM2nPY5WeW5_ST.mp4"
          muted
          autoPlay={"autoplay"}
          preload="auto"
          loop
        >
        </video> */}
      </div>
    </Backdrop>
  );
}

export default BackdropRotate;

import { Box, Typography } from '@mui/material'
import React from 'react'

const index = ({Images,className}) => {
  return (
    <Box className={className}>
       <img src={Images} alt="Images" className='line' height={'5px'} width={'80px'}/>
       <Typography>
         
       </Typography>
    </Box>
  )
}

export default index

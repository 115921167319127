import { Box, Typography } from '@mui/material'
import React from 'react';
import "./index.css"
// import redball from "../../images/Red-ball.png";


const index = ({ Images, className, text,
  text1,
  text3,
  text4,
  text2, handleActionButton, number, style }) => {

  

  return (

    <Box xs={6} md={6} lg={12} className={className} style={style}>
      <img src={Images} alt="Images"
        className='ActionButtonMega3ball'
        // style={{ maxWidth: '100%' }}
        // sx={{maxWidth:{xs:'50%',md:'100%'}}}
        onClick={() => {
          var returnState = false;
          number?.map((item) => {
            if (item?.length < 3) {
              // console.log("item1", item);
              returnState = true;
            }else if(item == null){
              returnState = true;
            }
          });
          if (text === 'CLEAR') {
            if (number?.length >= 0) {
              handleActionButton()
            }
          } else if (text === 'LUCKY PICK') {
            console.log("Comming", number,returnState)
            if (number?.length <= 8 || returnState) {
              handleActionButton()
            }
          }
          else {
            handleActionButton()
          }
        }} />
      <Typography
        variant="body2"
        fontFamily={'Bebas'}
        fontSize={30}
        className="Name"
        style={{ pointerEvents: "none", gap:'5px' }}
        sx={{ fontSize: { xs: '16px', sm:'25px', md: '20px', lg: '30px' } }}
      >
        {text}
       {(text1 || text2) && <div className="textContainer hfhf">
          <span className="text1">{text1}</span>
          <span className="text2" style={{padding:"0px 10px"}}>{text2}</span>
        </div>}
       {(text3 >= 0 || text4) && <div className="textCoin">
          <span className="text3">{text3}</span>
          <span className="text4">{text4}</span>
        </div>}
      </Typography>
    </Box>
  )
}

export default index

import { Box, Divider } from '@mui/material'
import React from 'react'

 const SameDayGameHistory = ({todayGameHistory}) => {
  return (
    <>
    {todayGameHistory && todayGameHistory?.length > 0 &&
        todayGameHistory.map((a, i) => (
          <>
            <Box key={i} className="historyBlock">
              <p className="numbringPlayerTwoHistory">
                {i + 1 === 1
                  ? "1st"
                  : i + 1 === 2
                    ? "2nd"
                    : i + 1 === 3
                      ? "3rd"
                      : `${i + 1}th`}
              </p>
              <Box className="historyBoxIcon">{a?.history?.[0]?.[0]}</Box>
              <Box className="historyBoxIcon2">
                {a?.history?.[0]?.[1]}
              </Box>
            </Box>
            {(i + 1) % 7 === 0 ? <Divider /> : null}
          </>
        ))}
        </>
  )
}
export default SameDayGameHistory;

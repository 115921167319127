
export default function generateThreeNonMatchingRandomNumbers(num1, num2) {
    let result = [];
    
    if (num1 !== undefined && num2 === undefined) {
      result.push(num1);
      while (result.length < 3) {
        let randomNum = Math.floor(Math.random() * 10);
        if (randomNum !== num1 && !result.includes(randomNum)) {
          result.push(randomNum);
        }
      }
    } else if (num1 !== undefined && num2 !== undefined) {
      result.push(num1, num2);
      while (result.length < 3) {
        let randomNum = Math.floor(Math.random() * 10);
        if (randomNum !== num1 && randomNum !== num2 && !result.includes(randomNum)) {
          result.push(randomNum);
        }
      }
    } else {
      while (result.length < 3) {
        let randomNum = Math.floor(Math.random() * 10);
        if (!result.includes(randomNum)) {
          result.push(randomNum);
        }
      }
    }
    
    return result;
  }

 
import { Box, TablePagination, Typography } from "@mui/material";
import React from "react";
import TableSection from "../UI/Table";
import moment from "moment";
import { PlayerHistoryForMegaThreeBall, PlayerHistoryForMegaTwoBall } from "../../APIHandler/API";
import { useSearchParams } from "react-router-dom";

export const PlayerGameHistory = ({ playerHistoryData, betFrom, searchDate,setPlayerHistoryData,gameTitle,totalData,setTotalData }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("placedBetNumber");
  const [params] = useSearchParams();

  const playerHistoryFunc = async (data) => {
    const resp = await PlayerHistoryForMegaTwoBall(data);
    setPlayerHistoryData(resp?.histories);
    setTotalData(prev=>resp?.count)
    // console.log("Mega2",{resp})
  };
  const playerHistoryFuncForMega3 = async (data) => {
    const resp = await PlayerHistoryForMegaThreeBall(data);
    // console.log({resp},"Mega3")
    // setPlayerHistoryData(resp);
    setPlayerHistoryData(resp?.histories);
    setTotalData(prev=>resp?.count)
  };
   let getPlayerHistoryData=(data)=>{
      if (gameTitle == "Mega 2 ball") {
        //Get mega2 player history
        playerHistoryFunc(data);
      } else {
        playerHistoryFuncForMega3(data);
      }
   }
  const handleChangePage = (event, newPage) => {
    // console.log("new Page", newPage);
    setPage(newPage);
    let data = {
      token: params.get("token"),
      date: moment(searchDate).format("YYYY-MM-DD"),
      pageNumber: newPage,
      pageSize: 20,
    };
   getPlayerHistoryData(data)
    document.querySelector(".userGameHistoryScreen").scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const visibleHistoryRows = React.useMemo(
    () =>
      stableSort(playerHistoryData, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, playerHistoryData]
  );

  return (
    <Box style={{overflowY:"auto",overflowX:"hidden"}} >
      {visibleHistoryRows?.map((item, i) => (
        <Box key={i} sx={{ mb: 2 }} >
          <HeaderSection item={item} />
          <Box sx={{ mt: 2 }}>
            <TableSection
              item={item}
              RoyalBet={betFrom}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Box>
        </Box>
      ))}
      {visibleHistoryRows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={totalData}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

const HeaderSection = ({ item }) => {
  const { gameSessionId, createdDate } = item;

  return (
    <Box className="bgImage Header_container">
      <Box>
        <Typography variant="h5" className="title_key">
          Session ID:
        </Typography>
        <Typography variant="body2" className="title_value">
          {gameSessionId}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" className="title_key">
          Time:
        </Typography>
        <Typography variant="body2" className="title_value">
          {moment(createdDate).format("LT")}
        </Typography>
      </Box>
    </Box>
  );
};

import { Box } from '@mui/material';
import React from 'react'
import ReactModal from 'react-modal';
import PopupButtonsHandler from '../../../component/PopupButtonsHandler';
import clickOkImages from "../../../images/SwitchGame/yes-button.png";
import useSound from 'use-sound';
import SoftButtonClickAudio from '../../../Asset/audio/SoftGameButtonClick.mp3';
import "../../../player/Mega2ball/index.css";

const EndSessionModal = ({ endSessionPopup, showMute, setEndSessionPopup }) => {
    const [playSoftButtonClickAudio] = useSound(SoftButtonClickAudio);
    const handleSubmit = () => {
        setEndSessionPopup(false);
        if (showMute === false) {
            playSoftButtonClickAudio();
        }
    }
    return (
        <ReactModal isOpen={endSessionPopup} className="showStop-endSessionPopup"
            onAfterOpen={() => document.body.style.overflow = 'hidden'}
            onAfterClose={() => document.body.style.overflow = 'unset'}>
            <Box className="endSession-Popup-bg">
                <Box className="endSession-Popup-text">
                    The gameSession has been end !
                </Box>
                <Box className="button-Okay-endSessionPopup ">
                    <PopupButtonsHandler
                        Images={clickOkImages}
                        text={"Okay"}
                        className="ClickOkayImageEndSession"
                        clickOkayPopup={() => handleSubmit()}
                    />
                </Box>
            </Box>
        </ReactModal>
    )
}

export default EndSessionModal